<template>
  <Modal
    v-if="isModalActive"
    id="stageModal"
    v-model:isOpen="isModalActive"
    :loading="loading"
    :is-disabled="loading.save"
    :header="modalHeaderHandler"
    size="is-large"
    :save-need="false"
    :style="{ minHeight: modalMinHeight }"
    @save="onSubmit"
  >
    <h3 v-if="warning" class="warningText">
      ESTA OPERACIÓN PUEDE RESULTAR EN LA PÉRDIDA DE INFORMACIÓN
    </h3>
    <StageMonthlyRunForm
      v-if="!warning"
      ref="form"
      v-model:stateForms="stateForms"
      v-model:isEdit="isEditable"
      v-model:isStage="fromStage"
      v-model:rowData="rowData"
      :monthly-run-id="monthlyRunId"
    />
    <template #footer>
      <Button
        v-if="stageUsed && isEdit"
        label="Eliminar etapa"
        variant="danger"
        :disabled="loading.save"
        @click="onDeleteStage"
      />
      <Button
        label="Cancelar"
        variant="danger"
        inverted
        :disabled="loading.get"
        @click="isModalActive = false"
      />
      <Button
        v-if="hidePrev"
        label="Anterior"
        variant="dark"
        :disabled="loading.get"
        @click="onPrevForm"
      />
      <Button
        v-if="hideNext"
        :disabled="disabledNext"
        label="Siguiente"
        variant="primary"
        @click="onNextForm"
      />
      <Button
        v-else
        :label="actionButtonText"
        :disabled="disabledNext || loading.get"
        :loading="loading.get"
        variant="primary"
        @click="onsubmit"
      />
    </template>
  </Modal>
</template>
<script>
import { reactive, watch, ref, toRefs, getCurrentInstance, computed, onMounted } from 'vue';
import { StageMonthlyRunForm } from '../Forms';
import useDialog from '@/utils/composables/useDialog';
import { Modal, Button } from '@/components';
export default {
  components: {
    Button,
    Modal,
    StageMonthlyRunForm,
  },
  props: {
    isOpen: { type: Boolean, default: false },
    isEdit: { type: Boolean, dafault: false },
    isStage: { type: Boolean, dafault: false },
    monthlyRunId: { type: [Number, String], default: null },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['update:active', 'update:is-open', 'update:is-edit', 'success', 'success:save'],
  setup(props, { emit }) {
    const loading = reactive({ save: false, get: false });
    const { isEdit, isStage, isOpen, data } = toRefs(props);
    const isEditable = ref(props.isEdit);
    const fromStage = ref(props.isStage);
    const isModalActive = ref(props.isOpen);
    const warning = ref(false);
    const rowData = ref(props.data);
    const stageUsed = ref(false);
    const stageName = ref('');
    const { Notify } = useDialog();
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const form = ref(null);
    const stateForms = ref({
      initialStepForm: {
        type: 'initialStepForm',
        isActive: true,
        isReady: false,
        isInitialStep: true,
        next: 'aditionalDataForm',
      },
      filesForm: {
        type: 'filesForm',
        isActive: false,
        isReady: false,
        next: 'aditionalDataForm',
        prev: 'initialStepForm',
      },
      aditionalDataForm: {
        type: 'aditionalDataForm',
        isActive: false,
        isReady: false,
        isFinalStep: true,
        prev: 'initialStepForm',
      },
    });

    const onNextForm = () => {
      if (isEditable.value && rowData.value.in_use === 1 && !warning.value) warning.value = true;
      else warning.value = false;
      if (warning.value === false) {
        const { type, next } = Object.values(stateForms.value).find((form) => form.isActive);
        stateForms.value[type].isActive = false;
        stateForms.value[next].isActive = true;
      }
    };
    const onPrevForm = () => {
      const { type, prev } = Object.values(stateForms.value).find((form) => form.isActive);
      stateForms.value[type].isActive = false;
      stateForms.value[prev].isActive = true;
    };
    const onsubmit = async () => {
      loading.save = true;
      const formData = form.value.getFormatObject();
      const newStageData = form.value.setArrayNewStage();
      if (fromStage.value) {
        if (!isEditable.value) {
          try {
            await Api.post(`/stages`, newStageData);
            emit('success');
            Notify('success', 'Etapa creada', `Etapa creada`);
            emit('success:save');
            isModalActive.value = false;
          } catch (error) {
            console.log(error);
          }
        } else {
          try {
            await Api.put(`/stages/${rowData.value.id}`, newStageData);
            emit('success');
            Notify('success', 'Éxito', `Cambios guardados exitosamente`);
            emit('success:save');
            isModalActive.value = false;
          } catch (error) {
            console.log(error);
          }
        }
      } else {
        try {
          await Api.post(`/monthly-run/${props.monthlyRunId}/monthly-run-details`, formData);
          emit('success');
          Notify('success', 'Etapa agregada', `Etapa agregada al mes corriente actual`);
          isModalActive.value = false;
        } catch (error) {
          console.log(error);
        }
      }
      loading.save = false;
    };
    const stageInUse = async () => {
      loading.save = true;
      if (rowData.value.id) {
        try {
          const { data } = await Api.get(`/stages/${rowData.value.id}`);
          stageName.value = data.name;
          stageUsed.value = data.in_use === 0;
        } catch (error) {
          console.log(error);
        }
        loading.save = false;
      }
    };
    const onDeleteStage = async () => {
      try {
        await Api.delete(`/stages/${rowData.value.id}`);
        emit('success');
        Notify('success', 'Etapa eliminada con éxito', `Nombre: ${stageName.value}`);
        emit('success:save');
        isModalActive.value = false;
      } catch (error) {
        console.log(error);
      }
    };
    const modalMinHeight = computed(() => {
      return warning.value ? '110px' : '350px';
    });
    const disabledNext = computed(() => {
      const currentForm = Object.values(stateForms.value).find((form) => form.isActive);
      return !currentForm.isReady;
    });
    const hidePrev = computed(() => {
      const currentForm = Object.values(stateForms.value).find((form) => form.isActive);
      return !currentForm.isInitialStep;
    });
    const hideNext = computed(() => {
      const currentForm = Object.values(stateForms.value).find((form) => form.isActive);
      return !currentForm.isFinalStep;
    });
    const modalHeaderHandler = computed(() => {
      if (warning.value) return 'Editar etapa - ADVERTENCIA';
      return isEditable.value ? 'Editar etapa' : 'Añadir etapa';
    });
    const actionButtonText = computed(() => {
      return isEditable.value ? 'Editar etapa' : 'Agregar etapa';
    });

    watch(isOpen, isEdit, isStage, data, (value) => (isModalActive.value = value));
    watch(isModalActive, (value) => {
      emit('update:active', value);
      emit('update:is-open', value);
      emit('update:is-edit', value);
    });
    onMounted(() => {
      stageUsed.value = stageInUse();
    });

    return {
      // Data
      form,
      stateForms,
      loading,
      isModalActive,
      isEditable,
      fromStage,
      rowData,
      stageUsed,
      stageName,
      warning,

      // method
      onDeleteStage,
      onNextForm,
      onPrevForm,
      onsubmit,

      // computed
      disabledNext,
      hidePrev,
      hideNext,
      modalHeaderHandler,
      actionButtonText,
      modalMinHeight,
    };
  },
};
</script>
<style lang="sass" scoped>
.warningText
  color: red
  font-weight: 600
#stageModal
    :deep(.modal-content) > .card .card-content
      max-height: 70vh
      overflow-y: auto
</style>
