<template>
  <Field class="date-picker" :label="label" :required="required" :date-formater="dateFormater">
    <o-datepicker
      v-model="date"
      :placeholder="placeholder"
      :required="required"
      :date-formatter="dateFormater"
      :date-parser="dateParser"
      :icon="icon"
      :type="type"
      :icon-right="clearable && date ? 'close' : undefined"
      :icon-right-clickable="clearable"
      v-bind="{ ...datePickedProps }"
      :max-date="maxDate"
      :min-date="minDate"
      :mobile-modal="mobileModal"
      @icon-right-click="clearable ? clear() : $emit('icon-right-click')"
      @click="(e) => onClick(e)"
    >
      <template v-if="$slots.trigger" #trigger>
        <slot name="trigger" />
      </template>
    </o-datepicker>
  </Field>
</template>
<script>
import { ref, watch, toRefs } from 'vue';
import { Field } from '@/components';
import { usePickProps } from './conf/composables';
import moment from 'moment';
export default {
  components: {
    Field,
  },
  props: {
    placeholder: { type: String, default: '00/00/0000' },
    modelValue: { type: [Date, String], default: null },
    label: { type: String, default: '' },
    type: { type: String, default: undefined },
    icon: { type: String, default: 'calendar-blank' },
    required: { type: Boolean, default: false },
    responseFormat: { type: String, default: 'DD/MM/YYYY' },
    maxDate: { type: Date || undefined, default: undefined },
    minDate: { type: Date || undefined, default: undefined },
    mobileModal: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
  },
  emits: ['update:model-value', 'click', 'icon-right-click'],
  setup(props, { attrs, emit }) {
    const { propsPicked: datePickedProps } = usePickProps('DatePickerProps', { props, attrs });
    const { modelValue } = toRefs(props);
    const date = ref(props.modelValue);
    watch(modelValue, (value) => (date.value = value));
    watch(date, (value) => emit('update:model-value', value));
    return {
      date,
      datePickedProps,
      dateFormater: (_date) => {
        let format = moment(_date).format('DD/MM/YYYY');
        if (props.type === 'month') format = moment(_date).format('MMMM YY').toUpperCase();
        return format;
      },
      dateParser: (_date) => moment(_date, 'DD/MM/YYYY').toDate(),
      onClick: (e) => emit('click', e),
      clear: () => (date.value = undefined),
    };
  },
};
</script>
<style lang="sass" scoped>
.date-picker :deep(.datepicker)
  .pagination .icon .mdi::before
      line-height: 24px
  .has-icons-left
    .input
      padding-left: 25px
    ~ .icon
      height: 35px
      width: 32px
      color: red
      &.is-right
        margin-right: 5px
        .mdi::before
          font-size: 18px
  .datepicker-months
    .datepicker-cell
      text-transform: capitalize
    a.datepicker-cell:not(.is-is-selected)
      color: $black
      &:hover
        background-color: hsl(0, 0%, 96%)
        color: hsl(0, 0%, 4%)
        cursor: pointer
</style>
