<template>
  <Modal
    v-model:is-open="isModalActive"
    class="ModalOverview"
    size="is-ex-large"
    :loading="loading"
    :is-disable="loading.save"
    :header="headerModal"
    @cancel="resetModal"
  >
    <h2 v-if="!isClient" class="mb-3 is-uppercase has-text-weight-bold">
      {{ title }}
    </h2>
    <o-tabs type="boxed">
      <o-tab-item>
        <template #header>
          <o-icon class="icon-header" icon="eye"></o-icon>
          <span class="header-title">Observaciones</span>
        </template>
        <h2 v-if="isClient" class="mb-3 is-uppercase has-text-weight-bold">
          {{ title }}
        </h2>
        <div class="comments-review">
          <template v-if="sObservations.length">
            <article
              v-for="({ name, body, date }, key) in sObservations"
              :key="`c-00${key}`"
              class="comment"
            >
              <ol :start="key + 1">
                <li>
                  {{ body }}
                  <p class="has-text-right observations-text mt-2">- {{ name }}, {{ date }}</p>
                </li>
              </ol>
            </article>
          </template>
          <div v-else class="empty">
            <h3>Sin Observaciones.</h3>
          </div>
        </div>
        <template v-if="allowAddObservations">
          <h2 class="mb-3 is-uppercase has-text-weight-bold">Hacer observación:</h2>
          <Field
            v-model="newObservation"
            :disabled="loading.send"
            type="textarea"
            maxlength="500"
          />
          <div class="is-flex sendComments">
            <Button
              variant="dark"
              label="Enviar observación"
              :loading="loading.send"
              :disabled="loading.send || !newObservation.length || newObservation.trim() == ''"
              @click.prevent="sendObservation"
            />
            <span class="ml-auto counter-field">{{ textLengthObservation }} / 500</span>
          </div>
        </template>
      </o-tab-item>
      <o-tab-item v-if="!isClient">
        <template #header>
          <o-icon class="icon-header" icon="message-reply-text"></o-icon>
          <span class="header-title">Comentarios</span>
        </template>
        <div class="comments-review">
          <template v-if="sComments.length">
            <article v-for="(comment, key) in sComments" :key="`c-00${key}`" class="comment">
              <ol :start="key + 1">
                <li>
                  {{ comment }}
                </li>
              </ol>
            </article>
          </template>
          <div v-else class="empty">
            <h3>Sin comentarios.</h3>
          </div>
        </div>
        <h2 class="mb-3 is-uppercase has-text-weight-bold">Hacer comentario:</h2>
        <Field v-model="newComment" :disabled="loading.send" type="textarea" maxlength="500" />
        <div class="is-flex sendComments">
          <Button
            variant="dark"
            label="Enviar comentario"
            :loading="loading.send"
            :disabled="loading.send || !newComment.length || newComment.trim() == ''"
            @click.prevent="sendComments"
          />
          <span class="ml-auto counter-field">{{ textLength }} / 500</span>
        </div>
      </o-tab-item>
    </o-tabs>
    <template #footer>
      <Button
        variant="primary"
        label="Cerrar"
        :disabled="loading.send || loading.get"
        @click.prevent="isModalActive = false"
      />
    </template>
  </Modal>
</template>
<script>
import Modal from '../Modal.vue';
import { Field, Button } from '@/components';
import useDialog from '@/utils/composables/useDialog';
import { useComponentUtils } from '../conf/composables';
import { useAuthStore } from '@/store';
import { watch, toRefs, ref, computed, getCurrentInstance } from 'vue';
import { Permissions } from '@/utils/Secure';
import { PermissionValidator } from '@/utils/Secure';
import moment from 'moment';
export default {
  components: {
    Modal,
    Field,
    Button,
  },
  props: {
    id: { type: [String, Number], default: null },
    title: { type: String, default: '' },
    auditingId: { type: [String, Number], default: null },
    isOpen: { type: Boolean, default: false },
    comments: { type: Array, default: () => [] },
    observations: { type: Array, default: () => [] },
    isClient: { type: Boolean, default: false },
  },
  emits: ['update:active', 'update:is-open', 'update:comments', 'update:observations'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const auth = useAuthStore();
    const Api = proxy?.Api;
    const { Normalize } = useComponentUtils();
    const { Notify } = useDialog();
    const { isOpen, comments, observations } = toRefs(props);
    const isModalActive = ref(props.isOpen);
    const newComment = ref('');
    const newObservation = ref('');
    const loading = ref({ send: false });
    const sComments = ref(props.comments ?? []);
    const sObservations = ref(props.observations ?? []);
    const resetModal = () => {
      newComment.value = '';
      newObservation.value = '';
    };
    const allowAddObservations = computed(() => {
      const { Observations } = Permissions.AuditingConcepts;
      return PermissionValidator(Observations);
    });
    watch(isOpen, (value) => (isModalActive.value = value));
    watch(isModalActive, (value) => {
      emit('update:active', value);
      emit('update:is-open', value);
      if (!value) resetModal();
    });
    watch(observations, (value) => (sObservations.value = value));
    watch(sObservations, (value) => emit('update:observations', value));
    watch(comments, (value) => (sComments.value = value));
    watch(sComments, (value) => emit('update:comments', value));
    return {
      allowAddObservations,
      isModalActive,
      newComment,
      newObservation,
      loading,
      headerModal: computed(() => `Observaciones ${props.isClient ? '' : 'y comentarios'} `),
      textLength: computed(() => newComment.value.length),
      textLengthObservation: computed(() => newObservation.value.length),
      resetModal,
      sComments,
      sObservations,
      sendObservation: async () => {
        loading.value.send = true;
        try {
          let _observations = JSON.stringify([
            ...sObservations.value,
            {
              name: auth.AUTH.full_name,
              body: Normalize(newObservation.value, {
                replace: { find: ['\\n'], value: [' '] },
              }),
              date: moment(new Date()).format('DD/MM/YYYY'),
            },
          ]);
          await Api.patch(`/auditing/${props.auditingId}/concept/${props.id}/observations`, {
            observations: _observations,
          });
          sObservations.value = JSON.parse(_observations);
          resetModal();
          Notify('success', 'Observación enviada', 'La observación se ha enviado"');
        } catch (error) {
          console.log(error);
          Notify('danger', 'No se logró enviar la observación');
        }
        loading.value.send = false;
      },
      sendComments: async () => {
        loading.value.send = true;
        try {
          let _comments = JSON.stringify([
            ...sComments.value,
            Normalize(newComment.value, {
              replace: { find: ['\\n'], value: [' '] },
            }),
          ]);
          await Api.put(`/auditing/${props.auditingId}/concept/${props.id}`, {
            comments: _comments,
          });
          sComments.value = JSON.parse(_comments);
          resetModal();
          Notify('success', 'Comentario enviado', 'El comentario se ha enviado"');
        } catch (error) {
          console.log(error);
          Notify('danger', 'No se logró enviar el comentario');
        }
        loading.value.send = false;
      },
    };
  },
};
</script>
<style lang="sass" scoped>
.ModalOverview
  :deep(.animation-content)
    width: 868px
    .card-content
      color: $black
      overflow: hidden
      padding-bottom: 24px
      min-height: 500px
      .sendComments
        .button
          width: 180px
  .observations-text
    color: $grey-info
    font-size: $f-xss
  .empty
    color: $grey-dark
    font-size: $fh-md
    display: flex
    align-items: center
    justify-content: center
    text-align: center
    width: 100%
    height: 140px
    h3
      font-weight: 600
</style>
<style lang="sass">
.comments-review
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  min-height: 141px
  max-height: calc(100vh - 518px)
  overflow-y: auto
  .comment
    border-radius: 8px
    background-color: $color-blue-hues
    margin-bottom: 16px
    padding: 10px
    padding-left: 26px
    min-width: 355px
    width: 100%
    height: fit-content
    max-width: calc( 50% - 8px )
    font-size: 13px
    word-break: break-word
.b-tabs
  .tabs
      a
        color: $black
      .header-title
        font-weight: 500
        font-size: 13px
      .is-active
        .header-title
            color: $primary
  .icon-header
      margin-right: 4px
      .mdi::before
          font-size: 20px
  :deep(.tab-content)
      padding: 0
      padding-top: 16px
</style>
