<template>
  <div
    v-if="isActive || isAdminAuditor"
    :class="classes"
    class="is-flex w-100 is-flex-direction-column"
  >
    <div v-if="lastStatusUpdate" class="date-header f-xxs has-text-white has-text-centered">
      <p><span class="has-text-weight-semibold">Fecha:</span> {{ lastStatusUpdate }}</p>
    </div>
    <Button
      class="status-button is-flex-grow-1 w-100"
      :loading="loading"
      @click.stop="$emit('click', $event)"
    >
      <p class="status-title f-xs has-text-weight-semibold mb-1">
        {{ stageData.stage_name }}
      </p>
      <p
        class="text-body f-xxs has-text-weight-semibold"
        :class="`has-text-color-${stageData.indicator}`"
      >
        {{ isActive ? currentStates.text : 'DESACTIVADO' }}
        <o-tooltip
          v-if="stageData.responsible_stage"
          class="is-capitalized"
          :label="tagResponsible.text"
          variant="info"
          position="bottom"
        >
          <o-icon class="ml-1" :icon="tagResponsible.icon" size="small" />
        </o-tooltip>
      </p>
      <Tag v-if="!isOmissible" rounded label="No omisible" size="small" variant="warning" />
    </Button>
    <div v-if="allowUpdateMonthlyRunStage" class="status-handler w-100">
      <template v-for="{ label, key, iconLeft } in stateButtons" :key="`${label} - ${key}`">
        <o-tooltip class="auto-tooltip" variant="info" :label="label">
          <Button
            class="p-0 w-100"
            :disabled="isDisableChangeStatus || statusSelected == key"
            :class="statusSelected == key ? 'selected' : ''"
            :icon-left="iconLeft"
            @click="onChangeStatus(key)"
          />
        </o-tooltip>
      </template>
    </div>
  </div>
</template>
<script>
import { toRefs, computed, ref, watch, reactive } from 'vue';
import useDialog from '@/utils/composables/useDialog';
import { useProgrammatic } from '@oruga-ui/oruga-next';
import ModalConfirmStatus from './modals/ModalConfirmStatus.vue';
import { Tag, Button } from '@/components';
import { Permissions } from '@/utils/Secure';
import { useContabilidad } from '@/utils/composables';
import { useComponentUtils } from '@/components/conf/composables';
import { useAuthStore } from '@/store';
import { PermissionValidator } from '@/utils/Secure';

export default {
  components: {
    Tag,
    Button,
  },
  props: {
    selected: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    isActive: { type: [Number, String], default: 0 },

    stageData: { type: Object, default: () => {} },
    stageStatusReload: { type: Function, default: null },
    status: {
      type: String,
      enum: ['done', 'doing', 'toDo', 'stoped', 'needAtention'],
      default: 'toDo',
    },
    disableChangeStatus: { type: Boolean, default: true },
  },
  emits: ['click'],
  setup(props) {
    const { oruga } = useProgrammatic();
    const { Notify } = useDialog();
    const isModalActive = ref(false);
    const { stageData, status, selected } = toRefs(props);
    const isOmissible = ref(props.stageData.is_omissible);
    const stateButtons = reactive({
      pendiente: {
        key: 1,
        label: 'Pendiente',
        iconLeft: 'clipboard-clock-outline',
      },
      enTranscurso: {
        key: 2,
        label: 'En transcurso',
        iconLeft: 'timer-sand-complete',
      },
      completada: {
        key: 3,
        label: 'Completada',
        iconLeft: 'check-circle',
      },
    });
    const statusSelected = ref(props.stageData.monthly_run_stage_status_id);
    const isLoading = ref(false);
    const data = ref({});
    const { getStatusNameByID, getStateByName } = useContabilidad();
    const { roles, fomatDateDMY } = useComponentUtils();
    const { getAUTH } = useAuthStore();
    const userRole = getAUTH.role;

    const promptModal = async () => {
      const instance = oruga.modal.open({
        component: ModalConfirmStatus,
        canCancel: false,
        props: {
          headerTitle: '¿Estás seguro de cambiar la etapa de estado?',
          data: data.value,
          message: `La etapa <b>${data.value.stageName}</b> cambiará al estado <b>${data.value.nameState}</b>`,
          stageStatusReload: props.stageStatusReload,
        },
        trapFocus: true,
      });
      return instance.promise;
    };
    const prepareData = (status_id, nameState) => {
      const { monthly_run_id, stage_name, id } = stageData.value;
      return {
        stageName: stage_name,
        nameState,
        monthlyId: monthly_run_id,
        id,
        status_id,
      };
    };
    const onChangeStatus = async (status_id) => {
      let nameState = getStatusNameByID(status_id).toUpperCase();
      data.value = prepareData(status_id, nameState);
      const modalEmit = await promptModal();
      if (modalEmit?.action === 'yes') {
        try {
          statusSelected.value = data.value.status_id;
          Notify(
            'success',
            'Estado de la etapa cambiado',
            `La etapa <b>${stageData.value.stage_name}</b> cambió al estado <b>${nameState}</b>`,
          );
        } catch (error) {
          console.log(error);
        }
      }
    };

    const isAdminAuditor = computed(
      () => userRole === roles.Administrador || userRole === roles.Auditor,
    );
    const currentStates = computed(() => getStateByName(status.value));
    const lastStatusUpdate = computed(() => {
      const lastUpdaate = stageData.value.monthly_run_stage_status_datetime;
      return lastUpdaate ? fomatDateDMY(stageData.value.monthly_run_stage_status_datetime) : null;
    });
    const allowUpdateMonthlyRunStage = computed(() => {
      const { Update } = Permissions.MonthlyRun.MonthlyRunDetails;
      return PermissionValidator(Update);
    });
    const classes = computed(() => {
      const classes = ['wraper-custome'];
      if (selected.value) classes.push('is-selected');
      classes.push(`is-${currentStates.value.key}`);
      classes.push(props.isActive ? 'is-active' : 'no-aplica');
      return classes.join(' ');
    });
    const tagResponsible = computed(() => {
      const { responsible_stage } = stageData.value;
      return {
        text: responsible_stage === 'CLIENTE' ? 'Cliente' : 'Despacho',
        icon: responsible_stage === 'USUARIO' ? 'bank' : 'account-circle',
      };
    });
    const isDisableChangeStatus = computed(
      () => props.disableChangeStatus || isLoading.value || !props.isActive,
    );

    watch(
      () => stageData.value.monthly_run_stage_status_id,
      (value) => (statusSelected.value = value),
    );
    return {
      currentStates,
      getStateByName,
      statusSelected,
      stateButtons,
      onChangeStatus,
      isLoading,
      isModalActive,
      prepareData,
      data,
      isOmissible,
      isAdminAuditor,

      // computed
      classes,
      tagResponsible,
      lastStatusUpdate,
      isDisableChangeStatus,

      // permission
      allowUpdateMonthlyRunStage,
    };
  },
};
</script>

<style lang="sass" scoped>
.w-100
  width: 100%
.wraper-custome
  height: calc(100% - 37px)
  min-width: 133px
  max-width: 164px
  .date-header
    padding: 6px
    line-height: 10px
    background: $color-blue
    border-radius: 4px 4px 0 0
    border-bottom: 0 !important
    ~ :deep(.status-button)
      border-top: 0 !important

  :deep(.status-button).button
    height: auto
    min-height: 95px
    padding: 10px 12px
    background: linear-gradient(180deg, $white 70.83%, #F3F3F3 100%)
    max-width: 164px
    border-radius:  0
    .button-wrapper
      display: flex
      width: 100%
      .b-tooltip .icon
          margin: 0
      > span
        width: 100%
        text-wrap: wrap
        text-align: left
        .text-body
          margin-bottom: 10px
  .status-handler
    display: grid
    grid-template-columns: repeat(3, 1fr)
    :deep(.button)
      background: linear-gradient(180deg, $white 70.83%, #F3F3F3 100%)
      max-height: 40px
      border: 0
      .button-wrapper .icon
        margin: 0
        color: $grey-300
      .mdi.mdi-24px::before
        font-size: 16px
      &:hover:not(:disabled), &.selected
        background: unset
        background-color:$success-hover
        .icon
          color: $white
        &:disabled
          color:$grey-300
        &.selected, &.selected:disabled
          color: $white
          opacity: 1
          background-color: $primary
    > :first-child :deep(.button)
      border-radius: 0 0 0 4px
    > :nth-child(2) :deep(.button)
      border-radius: 0
    > :nth-child(3) :deep(.button)
      border-radius: 0 0 4px 0
  &.is-selected
    .date-header
      background: $black
      border: 1px solid $grey-light
    .date-header
      background: $black
      border: 1px solid $grey-light
    :deep(.status-button).button
      background: $black
      border: 1px solid $grey-light
      color: $color-white-blue
      cursor: unset

  &.is-active:not(.is-selected) :deep(.status-button).button:hover
    background: rgba($black, 0.8)
    color: $color-white-blue
  &.no-aplica
    :deep(.status-button).button
      background: $color-disable
      color: $grey-text-disable
    &.is-selected :deep(.status-button).button
      color: $color-white-blue
      background: $black
    .status-handler :deep(.button)
      background: $color-disable
      .icon .mdi.mdi-24px::before
        color: $grey-text-disable
      &:disabled
        opacity: 1
      &.selected
        background-color: $grey-text-disable
        .icon .mdi.mdi-24px::before
          color: $white
</style>
