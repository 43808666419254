<template>
  <Modal
    v-if="isModalActive"
    id="addStageModal"
    v-model:isOpen="isModalActive"
    :header="headerModal"
    size="is-large"
    :save-need="false"
  >
    <AddStage
      v-if="stateFormsController.addStageForm.isActive"
      v-model:initialPlannedData="initialPlannedData"
      v-model:initialMonthlyDetailsData="initialMonthlyDetailsData"
      :is-planned-run="isPlannedRun"
      :monthly-run-id="monthlyId"
      @update:ready="stateFormsController.addStageForm.isReady = $event"
      @update:stage="stageData = $event"
      @update:sequence="stageData.sequence = $event"
    />
    <p
      v-if="
        stateFormsController.warningForm.isActive &&
        isMonthlyWarningActive &&
        !initialMonthlyDetailsData &&
        !isDeleting
      "
      class="warning-p"
    >
      {{ warningText }}
    </p>
    <p
      v-if="
        isDeletingTextActive ||
        (initialPlannedData && stateFormsController.warningForm.isActive && isDeleting)
      "
      class="warning-p"
    >
      {{ deletingText }}
    </p>
    <p v-if="!isMonthlyWarningActive" class="warning-p" v-html="duplicatedMonthlyDetailsText" />
    <p
      v-if="
        stateFormsController.warningForm.isActive &&
        (initialPlannedData || initialMonthlyDetailsData) &&
        !isDeletePRActive
      "
      class="data-lose-p"
    >
      Esta operación puede resultar en pérdida de información.
    </p>
    <template #footer>
      <Button
        v-if="
          stateFormsController.addStageForm.isActive &&
          (initialPlannedData || initialMonthlyDetailsData)
        "
        :disabled="isLoading"
        :loading="buttonsLoading.delete"
        class="delete-button"
        label="Eliminar etapa"
        variant="danger"
        @click="handleDeleteStageButton"
      />
      <Button
        label="Cancelar"
        variant="danger"
        :disabled="isLoading"
        inverted
        @click="isModalActive = false"
      />
      <Button
        v-if="stateFormsController.addStageForm.isActive"
        :disabled="nextSaveDisabled"
        :loading="isLoading && !isDeleting"
        :label="labelSaveButton"
        variant="primary"
        @click="handleAddStageButton"
      />
      <Button
        v-if="stateFormsController.warningForm.isActive && !isDeleting"
        :disabled="isLoading"
        :loading="buttonsLoading.PlannedRun"
        :label="labelPRButton"
        variant="primary"
        @click="onSubmit('PR'), (buttonsLoading.PlannedRun = true)"
      />
      <Button
        v-if="isButtonSaveVisible"
        :disabled="isLoading || isAddMRDisabled"
        :loading="buttonsLoading.MonthlyRun"
        :label="labelMRButton"
        variant="primary"
        @click="onSubmit('MR'), (buttonsLoading.MonthlyRun = true)"
      />
      <Button
        v-if="isDeletePRActive"
        :loading="buttonsLoading.PlannedRun"
        class="delete-button"
        :disabled="isLoading"
        :label="deletePRLabel"
        variant="danger"
        @click="HandleDeletePlannedRun(), (buttonsLoading.PlannedRun = true)"
      />
      <Button
        v-if="
          stateFormsController.warningForm.isActive &&
          (initialPlannedData || initialMonthlyDetailsData) &&
          isDeleting &&
          !isDeleteMRinvisible &&
          isMRButtonVisible
        "
        :loading="buttonsLoading.MonthlyRun"
        class="delete-button"
        :disabled="isLoading"
        :label="deleteMRLabel"
        variant="danger"
        @click="handleReactiveDeleteButton(), (buttonsLoading.MonthlyRun = true)"
      />
    </template>
  </Modal>
</template>
<script>
import { watch, ref, toRefs, computed, getCurrentInstance } from 'vue';
import { Modal, Button } from '@/components';
import useDialog from '@/utils/composables/useDialog';
import { AddStage } from '../Forms';
import { useRoute } from 'vue-router';
import { useStages } from '@/utils/composables';
import { serviceName } from '@/config/constants';
export default {
  components: {
    Button,
    Modal,
    AddStage,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    plannedRunData: {
      type: Object,
      default: null,
    },
    monthlyDetailsData: {
      type: Object,
      default: null,
    },
    clientName: {
      type: String,
      default: '',
    },
    monthlyRunId: {
      type: [String, Number],
      default: null,
    },
    stateForms: {
      type: Object,
      default: () => ({
        addStageForm: {
          type: 'addStageForm',
          isActive: true,
          isReady: false,
          isInitialStep: true,
          next: 'warningForm',
        },
        warningForm: {
          type: 'warningForm',
          isActive: false,
          isReady: false,
          next: '',
        },
      }),
    },
  },
  emits: ['update:isOpen', 'call:reloadTable'],
  setup(props, { emit }) {
    const { isOpen } = toRefs(props);
    const isModalActive = ref(props.isOpen);
    const form = ref(null);
    const stateFormsController = ref(props.stateForms);
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const stageData = ref({});
    const route = useRoute();
    const customerId = ref(route.params.id);
    const isLoading = ref(false);
    const isAddMRHidden = ref(false);
    const isAddMRDisabled = ref(false);
    const isDeletePRActive = ref(false);
    const idMonthlyRun = ref(null);
    const { getServiceId } = useStages();
    const monthlyDetailsId = ref(null);
    const buttonsLoading = ref({ MonthlyRun: false, PlannedRun: false, delete: false });
    const monthlyId = ref(props.monthlyRunId);
    const monthlyStages = ref([]);
    const { Notify } = useDialog();
    const isMRButtonVisible = computed(() => {
      return !isDeleting.value && !initialPlannedData.value;
    });
    const isMonthlyWarningActive = ref(true);
    const isPlannedRun = computed(() => {
      return monthlyId.value ? false : true;
    });
    const isDeleteMRinvisible = ref(false);
    const isEditingMonthlyDetails = computed(() => {
      return initialMonthlyDetailsData.value ? true : false;
    });
    const deletePRLabel = computed(() => {
      return isDeleteMRinvisible.value ? 'Eliminar' : 'Eliminar futuras';
    });
    const deleteMRLabel = computed(() => {
      return initialMonthlyDetailsData.value ? 'Eliminar etapa' : 'Eliminar futuras y actual';
    });
    const isDeleting = ref(false);

    const initialPlannedData = computed(() => {
      return props.plannedRunData;
    });
    const initialMonthlyDetailsData = computed(() => {
      return props.monthlyDetailsData;
    });
    const currentClientName = computed(() => {
      return props.clientName;
    });
    const handleReactiveDeleteButton = async () => {
      isDeleting.value && initialPlannedData.value
        ? await handleDeleteBoth()
        : await handleDeleteStageButton();
    };
    const notificationsMessages = {
      plannedRun: 'Cambios guardados exitosamente en Planeación de corrida',
      MonthlyRun: 'Cambios guardados exitosamente',
      both: 'Cambios guardados exitosamente en Planeación de corrida y Corrida mensual',
    };

    const nextSaveDisabled = computed(() => {
      return !stateFormsController.value.addStageForm.isReady || isLoading.value;
    });

    const fireNotifySuccess = (message = '') => {
      initialPlannedData.value
        ? Notify(
            'success',
            `Etapa ${isDeleting.value ? 'eliminada' : 'editada'} con éxito`,
            `Cliente: ${currentClientName.value}`,
          )
        : Notify('success', 'Éxito', message);
    };

    const getMonthlyRun = async (year, month) => {
      try {
        const response = await Api.get(
          `/monthly-run?customer_id=${customerId.value}&fiscal_year=${year}&month=${month}`,
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    };

    const checkMonthly = async () => {
      try {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
        const response = await getMonthlyRun(currentYear, currentMonth);
        const stages = response?.data[0]?.stages;
        if (response.data.length > 0) {
          idMonthlyRun.value = response.data[0].id;
          monthlyStages.value = JSON.parse(stages);
        } else if (initialPlannedData.value) {
          isDeleting.value
            ? ((isDeleteMRinvisible.value = true), (isDeletePRActive.value = true))
            : (isAddMRHidden.value = true);
        } else {
          await onSubmit('PR');
        }
        isLoading.value = false;
      } catch (error) {
        console.error(error);
      }
    };

    const checkStageInMonthlyRun = () => {
      const currentStageId = initialPlannedData.value?.stage_id || stageData.value.id;
      const stage = monthlyStages.value.find((stage) => stage.stage_id === currentStageId);
      if (isDeleting.value) {
        isDeletePRActive.value = true;
        isDeleteMRinvisible.value = true;
        if (stage) {
          isDeleteMRinvisible.value = false;
          monthlyDetailsId.value = stage.id;
        }
        return;
      }
      if (stage) {
        if (initialPlannedData.value) {
          if (stageData.value?.name) {
            const currentMonthlyDetailID = stageData.value.id;
            const stageInMonthly = monthlyStages.value.find(
              (stage) => stage.stage_id === currentMonthlyDetailID,
            );
            if (stageInMonthly && stageData.value.id !== initialPlannedData.value.stage_id) {
              isAddMRDisabled.value = true;
              isMonthlyWarningActive.value = false;
            } else {
              isAddMRHidden.value = false;
              monthlyDetailsId.value = stage.id;
            }
          } else {
            isAddMRHidden.value = false;
            monthlyDetailsId.value = stage.id;
          }
        } else {
          isAddMRHidden.value = true;
        }
      } else {
        if (initialPlannedData.value) {
          isAddMRHidden.value = true;
        } else {
          isAddMRHidden.value = false;
        }
      }
    };

    const addStageToMonthlyDetails = async (data) => {
      await Api.post(
        `/monthly-run/${monthlyId.value || idMonthlyRun.value}/monthly-run-details`,
        data,
      );
    };

    const addStageToPlannedRun = async (data) => {
      await Api.post('/planned-run', data);
    };

    const changeSequencePlannedRun = async (data) => {
      await Api.put(`/planned-run/${initialPlannedData.value.id}`, data);
    };

    const changeSequenceMonthlyRun = async (data) => {
      await Api.put(
        `/monthly-run/${monthlyId.value || idMonthlyRun.value}/monthly-run-details/${
          monthlyDetailsId.value
        }`,
        data,
      );
    };

    const deleteStageInPlannedRun = async (id) => {
      await Api.delete(`/planned-run/${id}`);
    };
    const deleteStageInMonthlyRun = async (id) => {
      await Api.delete(
        `/monthly-run/${monthlyId.value || idMonthlyRun.value}/monthly-run-details/${id}`,
      );
    };

    const closeModalStopLoading = () => {
      isModalActive.value = false;
      isLoading.value = false;
    };

    const handlePostingToPlannedRun = async (data) => {
      try {
        await addStageToPlannedRun(data);
        fireNotifySuccess(notificationsMessages.plannedRun);
        emit('call:reloadTable');
      } catch (error) {
        console.error(error);
      } finally {
        closeModalStopLoading();
      }
    };

    const handleModifyPlannedRunStage = async (
      data,
      isNotifNeeded = true,
      isReloadNeeded = true,
    ) => {
      try {
        await deleteStageInPlannedRun(initialPlannedData.value.id);
        await addStageToPlannedRun(data);
        if (isNotifNeeded) fireNotifySuccess();
        if (isReloadNeeded) emit('call:reloadTable');
      } catch (error) {
        console.error(error);
      } finally {
        if (isReloadNeeded) closeModalStopLoading();
      }
    };

    const handleModifyMonthlyRunStage = async (
      data,
      isNotifNeeded = true,
      isReloadNeeded = true,
    ) => {
      try {
        await deleteStageInMonthlyRun(monthlyDetailsId.value);
        await addStageToMonthlyDetails(data);
        if (isNotifNeeded) fireNotifySuccess();
        if (isReloadNeeded) emit('call:reloadTable');
      } catch (error) {
        console.error(error);
      } finally {
        if (isReloadNeeded) closeModalStopLoading();
      }
    };

    const HandleDeletePlannedRun = async () => {
      try {
        isLoading.value = true;
        await deleteStageInPlannedRun(initialPlannedData.value.id);
        fireNotifySuccess();
        emit('call:reloadTable');
      } catch (error) {
        console.error(error);
      } finally {
        closeModalStopLoading();
      }
    };

    const handleDeleteFromMonthlyRun = async () => {
      try {
        isLoading.value = true;
        monthlyDetailsId.value = initialMonthlyDetailsData.value.id;
        await deleteStageInMonthlyRun(monthlyDetailsId.value);
        fireNotifySuccess(notificationsMessages.MonthlyRun);
        emit('call:reloadTable');
      } catch (error) {
        console.error(error);
      } finally {
        closeModalStopLoading();
      }
    };

    const nextStep = () => {
      const { type, next } = Object.values(stateFormsController.value).find(
        (form) => form.isActive,
      );
      stateFormsController.value[type].isActive = false;
      stateFormsController.value[next].isActive = true;
    };

    // eslint-disable-next-line no-unused-vars
    const handleDeleteBoth = async () => {
      try {
        isLoading.value = true;
        const deletePR = deleteStageInPlannedRun(initialPlannedData.value.id);
        const deleteMR = deleteStageInMonthlyRun(monthlyDetailsId.value);
        await Promise.all([deletePR, deleteMR]);
        fireNotifySuccess();
        emit('call:reloadTable');
      } catch (error) {
        console.error(error);
      } finally {
        closeModalStopLoading();
      }
    };

    const handleDeleteStageButton = async () => {
      isDeleting.value = true;
      if (stateFormsController.value.warningForm.isActive) {
        if (initialMonthlyDetailsData.value) await handleDeleteFromMonthlyRun();
      } else {
        if (initialPlannedData.value) {
          buttonsLoading.value.delete = true;
          isLoading.value = true;
          await checkMonthly();
          if (idMonthlyRun.value) await checkStageInMonthlyRun();
          isLoading.value = false;
          buttonsLoading.value.delete = false;
        }
        nextStep();
      }
    };

    const handleModifyBoth = async (PRdata, MRdata) => {
      const PRmodified = handleModifyPlannedRunStage(PRdata, false, false);
      const MRmodified = handleModifyMonthlyRunStage(MRdata, false, false);
      await Promise.all([PRmodified, MRmodified]);
      fireNotifySuccess();
      closeModalStopLoading();
      emit('call:reloadTable');
    };

    const handlePostingToMonthlyRun = async (data) => {
      try {
        await addStageToMonthlyDetails(data);
        fireNotifySuccess(notificationsMessages.MonthlyRun);
        emit('call:reloadTable');
      } catch (error) {
        console.error(error);
      } finally {
        closeModalStopLoading();
      }
    };

    const handlePostingBoth = async (MRdata, PRdata) => {
      try {
        const postMR = addStageToMonthlyDetails(MRdata);
        const postPR = addStageToPlannedRun(PRdata);
        await Promise.all([postMR, postPR]);
        fireNotifySuccess(notificationsMessages.both);
        emit('call:reloadTable');
      } catch (error) {
        console.error(error);
      } finally {
        closeModalStopLoading();
      }
    };

    const handleChangeSequencePlannedRun = async (data) => {
      try {
        await changeSequencePlannedRun(data);
        fireNotifySuccess();
        emit('call:reloadTable');
      } catch (error) {
        console.error(error);
      } finally {
        closeModalStopLoading();
      }
    };

    const handleChangeSequenceBoth = async (dataPR, dataMR) => {
      try {
        const PRpromise = changeSequencePlannedRun(dataPR);
        const MRpromise = changeSequenceMonthlyRun(dataMR);
        await Promise.all([PRpromise, MRpromise]);
        fireNotifySuccess();
        emit('call:reloadTable');
      } catch (error) {
        console.error(error);
      } finally {
        closeModalStopLoading();
      }
    };

    const handleOnSafePlannedRun = async (formatedData, from) => {
      if (!stageData.value?.name && initialPlannedData.value) {
        return from === 'PR'
          ? await handleChangeSequencePlannedRun(formatedData)
          : await handleChangeSequenceBoth(formatedData, { sequence: stageData.value.sequence });
      }
      if (initialPlannedData.value) {
        return from === 'PR'
          ? await handleModifyPlannedRunStage(formatedData)
          : await handleModifyBoth(formatedData, formatDataPostMonthlyRun(formatedData));
      }
      if (from === 'MR' && idMonthlyRun.value) {
        const formatedDataMR = formatDataMR(stageData.value);
        return await handlePostingBoth(formatedDataMR, formatedData);
      }

      return await handlePostingToPlannedRun(formatedData);
    };

    const handleAddStageToMonthlyRun = async () => {
      const formatedDataMR = formatDataMR(stageData.value);
      await handlePostingToMonthlyRun(formatedDataMR);
    };
    const handleJustChangeSecuence = async () => {
      try {
        monthlyDetailsId.value = initialMonthlyDetailsData.value.id;
        await changeSequenceMonthlyRun({ sequence: stageData.value.sequence });
        fireNotifySuccess(notificationsMessages.MonthlyRun);
        emit('call:reloadTable');
      } catch (error) {
        console.error(error);
      } finally {
        closeModalStopLoading();
      }
    };
    const handleEditReplaceMonthlyRun = async () => {
      try {
        monthlyDetailsId.value = initialMonthlyDetailsData.value.id;
        await deleteStageInMonthlyRun(monthlyDetailsId.value);
        await addStageToMonthlyDetails(formatDataPostMonthlyRun(stageData.value));
        fireNotifySuccess(notificationsMessages.MonthlyRun);
        emit('call:reloadTable');
      } catch (error) {
        console.error(error);
      } finally {
        closeModalStopLoading();
      }
    };
    const handleOnEditMonthlyRunDetails = async () => {
      stageData.value?.name
        ? await handleEditReplaceMonthlyRun()
        : await handleJustChangeSecuence();
    };

    const handleOnSafeMonthlyRun = async () => {
      isEditingMonthlyDetails?.value
        ? await handleOnEditMonthlyRunDetails()
        : await handleAddStageToMonthlyRun();
    };

    const onSubmit = async (from) => {
      isLoading.value = true;
      const formatedData = stageData.value?.name
        ? await formatData(stageData.value)
        : formatDataChangeSequence(
            initialPlannedData.value || initialMonthlyDetailsData.value,
            stageData.value.sequence,
          );

      if (isPlannedRun.value) {
        handleOnSafePlannedRun(formatedData, from);
      } else {
        handleOnSafeMonthlyRun();
      }
    };

    const formatData = async (data) => {
      const service_id = await getServiceId(serviceName.CONTABILIDAD);
      return {
        customer_id: customerId.value,
        service_id,
        stage_id: data.id,
        sequence: data.sequence,
        is_omissible: data.is_omissible,
      };
    };
    const formatDataPostMonthlyRun = (data) => {
      return {
        stage_id: data?.stage_id || data.id,
        sequence: data.sequence,
        is_omissible: data.is_omissible,
      };
    };

    const formatDataChangeSequence = (data, sequence) => {
      return {
        customer_id: data.customer_id,
        service_id: data.service_id,
        stage_id: data.stage_id,
        sequence: sequence,
        is_omissible: data.is_omissible,
        documents: data.documents,
      };
    };

    const formatDataMR = (data) => {
      return {
        stage_id: data.id,
        sequence: data.sequence,
        is_omissible: data.is_omissible,
      };
    };

    const handleNextButton = async () => {
      if (initialMonthlyDetailsData.value) {
        nextStep();
        return;
      }
      isLoading.value = true;
      await checkMonthly();
      if (!idMonthlyRun.value && !initialPlannedData.value) return;
      checkStageInMonthlyRun();
      isLoading.value = false;
      nextStep();
    };

    const handleAddStageButton = () => {
      isPlannedRun.value || initialMonthlyDetailsData.value ? handleNextButton() : onSubmit();
    };

    const headerModal = computed(() => {
      if (stateFormsController.value.addStageForm.isActive) {
        return initialPlannedData.value || initialMonthlyDetailsData.value
          ? 'Editar etapa'
          : 'Agregar etapa';
      }
      return isDeleting.value
        ? 'Eliminar etapa - ADVERTENCIA'
        : initialPlannedData.value || initialMonthlyDetailsData.value
        ? 'Editar etapa - ADVERTENCIA'
        : 'Agregar etapa - ADVERTENCIA';
    });
    const isDeletingTextActive = computed(() => {
      return (
        initialPlannedData.value &&
        idMonthlyRun.value &&
        monthlyDetailsId.value &&
        isDeleting.value &&
        stateFormsController.value.warningForm.isActive
      );
    });
    const deletingText = computed(() => {
      return 'Esta acción eliminará la etapa permanentemente. ¿Está seguro?';
    });
    const warningText = computed(() => {
      return initialPlannedData.value
        ? isAddMRHidden.value
          ? ``
          : `Se editará esta etapa en los meses futuros.`
        : `Se agregará esta etapa en los meses futuros.`;
    });

    const labelPRButton = computed(() => {
      if (initialMonthlyDetailsData.value) return `Editar etapa`;
      return initialPlannedData.value
        ? isAddMRHidden.value
          ? `Editar etapa`
          : `Editar futuras`
        : `Agregar a futuras`;
    });

    const labelMRButton = computed(() => {
      return `${initialPlannedData.value ? 'Editar' : 'Agregar a'} futuras y actual`;
    });

    const labelSaveButton = computed(() => {
      return initialPlannedData.value || initialMonthlyDetailsData.value
        ? 'Guardar'
        : 'Añadir etapa';
    });

    const duplicatedMonthlyDetailsText = computed(() => {
      return `La etapa <span class="duplicatedMRD-p">${stageData.value?.name}</span> solo se puede añadir a meses futuros, debido a que ya se encuentra incluida en el mes actual.`;
    });

    const isButtonSaveVisible = computed(() => {
      //(stateFormsController.value.warningForm.isActive && !isAddMRHidden.value &&!isDeleting.value &&!initialMonthlyDetailsData.value)
      return false;
    });
    const resetModal = () => {
      stateFormsController.value = {
        addStageForm: {
          type: 'addStageForm',
          isActive: true,
          isReady: false,
          isInitialStep: true,
          next: 'warningForm',
        },
        warningForm: {
          type: 'warningForm',
          isActive: false,
          isReady: false,
          next: '',
          prev: 'addStageForm',
        },
      };
      stageData.value = {};
      idMonthlyRun.value = null;
      monthlyDetailsId.value = null;
      buttonsLoading.value = { MonthlyRun: false, PlannedRun: false, delete: false };
      isLoading.value = false;
      isAddMRHidden.value = false;
      isDeleting.value = false;
      isDeletePRActive.value = false;
      isDeleteMRinvisible.value = false;
    };

    watch(
      isOpen,
      (value) => (
        (isModalActive.value = value),
        (isMonthlyWarningActive.value = true),
        (isAddMRDisabled.value = false)
      ),
    );
    watch(isModalActive, (value) => {
      emit('update:isOpen', value);
      if (!value) resetModal();
    });
    return {
      // Data
      form,
      isModalActive,
      stateFormsController,
      stageData,
      isLoading,
      isAddMRHidden,
      buttonsLoading,
      monthlyId,
      isMonthlyWarningActive,
      isAddMRDisabled,
      isDeleting,
      isDeletePRActive,
      isDeleteMRinvisible,
      isMRButtonVisible,

      // method
      handleAddStageButton,
      handleDeleteStageButton,
      handleReactiveDeleteButton,
      HandleDeletePlannedRun,
      onSubmit,

      // computed
      nextSaveDisabled,
      headerModal,
      isPlannedRun,
      warningText,
      initialPlannedData,
      labelPRButton,
      labelMRButton,
      labelSaveButton,
      duplicatedMonthlyDetailsText,
      initialMonthlyDetailsData,
      deletePRLabel,
      deleteMRLabel,
      deletingText,
      isDeletingTextActive,
      isButtonSaveVisible,
    };
  },
};
</script>
<style lang="sass" scoped>
#addStageModal
    :deep(.modal-content) > .card .card-content
      max-height: 70vh
      overflow-y: auto
    .warning-p
        color: $black
    :deep(.duplicatedMRD-p)
        font-weight: 600
    .data-lose-p
      color: red
      font-weight: 600
    :deep(.delete-button)
      min-width: 137.59px
</style>
