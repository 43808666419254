<template>
  <ModalAuditoria
    v-if="!isClient || isOpen"
    v-model:is-open="isOpen"
    v-model:data="auditingToEdit"
  />
  <Table
    id="table-auditorias"
    ref="tableAuditorias"
    v-model:loading="isLoading"
    hide-table-on-loading
    :class="isClient ? 'is-client' : ''"
    :api-url="apiUrl"
    :header="auditingHeader"
    :params="params"
    :focusable="false"
    paginated
  >
    <template v-if="!isClient" #header>
      <div class="nav-search">
        <DatePicker v-model="startDate" clearable response-format="YYYY/MM/DD" label="Inicio:" />
        <DatePicker v-model="endDate" clearable response-format="YYYY/MM/DD" label="Fin:" />
        <Field
          v-model="fieldSearch.value"
          class="search"
          :placeholder="placeholderSearh"
          icon-right="magnify"
          text-transform="upper"
        >
          <template #addonsLeft>
            <Dropdown
              v-model:selected="itemSelected"
              label="Campos de búsqueda"
              variant="info"
              :data="dropdownItems"
            />
          </template>
        </Field>
        <Button
          v-if="allowAddAuditing"
          class="addAuditing ml-2"
          icon-left="plus"
          label="Añadir auditoría"
          variant="primary"
          @click="() => (isOpen = true)"
        />
      </div>
    </template>
    <o-table-column v-slot="{ row }" sortable label="Auditoría" field="name" sort-icon-size="large">
      <p class="is-uppercase">
        {{ row.name }}
      </p>
      <div class="is-flex mx-auto is-justify-content-center">
        <Tag rounded class="tag-type"
          >Tipo:
          <span class="has-text-weight-bold is-uppercase ml-1">{{ row.auditing_type_name }}</span>
        </Tag>
        <Tag
          v-if="row.status === STATUS.FINALIZADA"
          label="Cerrado"
          class="ml-2"
          variant="success"
          rounded
        />
      </div>
    </o-table-column>
    <o-table-column
      v-if="isClient"
      v-slot="props"
      sortable
      label="Responsable"
      field="responsible_name"
      sort-icon-size="large"
    >
      <p>
        {{ props.row.responsible_name }}
      </p>
    </o-table-column>
    <o-table-column
      v-if="!isClient"
      v-slot="props"
      sortable
      label="Nombre de cliente"
      field="customer_name"
      sort-icon-size="large"
    >
      <p>
        {{ props.row.customer_name }}
      </p>
    </o-table-column>
    <o-table-column
      v-if="!isClient"
      v-slot="props"
      sortable
      label="RFC"
      field="customer_tin"
      sort-icon-size="large"
    >
      <p>
        {{ props.row.customer_tin }}
      </p>
    </o-table-column>
    <o-table-column
      v-slot="props"
      sortable
      label="Fecha de inicio"
      field="start_date"
      sort-icon-
      size="large"
    >
      <p>
        {{ formatDate(props.row.start_date) }}
      </p>
    </o-table-column>
    <o-table-column
      v-slot="props"
      sortable
      label="Fecha de término"
      field="end_date"
      sort-icon-size="large"
    >
      <p>
        {{ formatDate(props.row.end_date) }}
      </p>
    </o-table-column>
    <o-table-column v-if="allowEditAuditing" v-slot="{ row }">
      <Button
        v-if="row.status !== STATUS.FINALIZADA"
        class="btn-edit"
        icon-left="pencil"
        variant="primary"
        @click="onEditAuditing(row)"
      />
    </o-table-column>
    <o-table-column v-slot="{ row }" width="246">
      <div class="actions-auditing">
        <Button
          class="btn-show-auditoria mx-auto"
          icon-left="arrow-right-bold-box"
          variant="primary"
          label="Auditoría completa"
          @click="onNavigate(row)"
        />
        <div
          v-if="row.status === STATUS.FINALIZADA && allowDownloadAuditingFiles"
          class="is-flex mt-2 auditing-download"
        >
          <DownloadsAuditingDocuments :auditing-id="row.id" />
        </div>
      </div>
    </o-table-column>
  </Table>
</template>
<script>
import { Table, Button, Dropdown, Field, DatePicker, ModalAuditoria, Tag } from '@/components';
import moment from 'moment';
import { useRouter } from 'vue-router';
import { userRoles } from '@/config/constants';
import { useAuthStore } from '@/store';
import { useComponentUtils } from '@/components/conf/composables';
import { ref, toRefs, watch, computed, reactive, onMounted } from 'vue';
import { Permissions } from '@/utils/Secure';
import { PermissionValidator } from '@/utils/Secure';
import DownloadsAuditingDocuments from './FragmentsAuditing/DownloadsAuditingDocuments';
export default {
  components: {
    Button,
    DatePicker,
    Dropdown,
    Field,
    ModalAuditoria,
    Table,
    Tag,
    DownloadsAuditingDocuments,
  },
  inheritAttrs: false,
  props: {
    loading: { type: Boolean, default: false },
    auditing: { type: Object, default: () => {} },
  },
  emits: ['update:loading', 'update:auditing'],
  setup(props, { emit }) {
    const router = useRouter();
    const { getAUTH } = useAuthStore();
    const { loading } = toRefs(props);
    const isLoading = ref(props.loading);
    const apiUrl = ref('auditing');
    const tableAuditorias = ref(null);
    const { table } = useComponentUtils();
    const isOpen = ref(false);
    const fieldSearch = reactive({ oldValue: '', value: '', timeout: null });
    const dropdownItems = ref([
      { name: 'Auditoría', params: 'name' },
      { name: 'Cliente', params: 'customer_name' },
      { name: 'RFC', params: 'customer_tin' },
      { name: 'Tipo', params: 'auditing_type_name' },
    ]);
    const STATUS = {
      CREADO: 'CREADO',
      EN_PROGRESO: 'EN PROGRESO',
      FINALIZADA: 'FINALIZADA',
    };
    const itemSelected = ref(dropdownItems.value[0]);
    const startDate = ref();
    const endDate = ref();
    const auditingToEdit = ref({});

    const onEditAuditing = (_auditing) => {
      const { end_date, start_date } = _auditing;
      auditingToEdit.value = {
        ..._auditing,
        end_date: moment(end_date).toDate(),
        start_date: moment(start_date).toDate(),
      };
      isOpen.value = true;
    };
    const params = computed(() => {
      const { params } = itemSelected.value;
      const aParams = ['sort=-id'];
      if (startDate.value)
        aParams.push(`start_date=${moment(startDate.value, 'YYYY/MM/DD').format('YYYY-MM-DD')}`);
      if (endDate.value)
        aParams.push(`end_date=${moment(endDate.value, 'YYYY/MM/DD').format('YYYY-MM-DD')}`);
      if (fieldSearch.oldValue.length) aParams.push(`${params}=${fieldSearch.oldValue}`);
      return aParams;
    });
    const allowAddAuditing = computed(() => {
      const { Create } = Permissions.Auditing;
      return PermissionValidator(Create);
    });
    const allowEditAuditing = computed(() => {
      const { Update } = Permissions.Auditing;
      return PermissionValidator(Update);
    });
    const allowDownloadAuditingFiles = computed(() => {
      const { download } = Permissions.Auditing;
      return PermissionValidator(download);
    });
    const isClient = computed(() => getAUTH.role === userRoles.CLIENTE);
    const auditingHeader = computed(() =>
      isClient.value ? `BIENVENIDO ${getAUTH.full_name}`.toUpperCase() : 'Auditorías'.toUpperCase(),
    );
    watch(isOpen, (value) => {
      if (!value)
        auditingToEdit.value = {
          name: '',
          description: '',
          start_date: null,
          end_date: null,
          customer_service_id: null,
          auditing_type_id: null,
        };
    });
    watch(loading, (value) => (isLoading.value = value));
    watch(isLoading, (value) => emit('update:loading', value));
    watch(
      () => fieldSearch.value,
      (value) => {
        clearTimeout(fieldSearch.timeout);
        fieldSearch.timeout = setTimeout(() => {
          fieldSearch.oldValue = value;
        }, 500);
      },
    );
    const reload = async () => {
      if (tableAuditorias.value) tableAuditorias.value.reload();
    };
    onMounted(() => {
      if (tableAuditorias.value) table.value = tableAuditorias.value;
    });
    return {
      allowAddAuditing,
      allowEditAuditing,
      allowDownloadAuditingFiles,
      auditingToEdit,
      auditingHeader,
      isLoading,
      isClient,
      apiUrl,
      onEditAuditing,
      tableAuditorias,
      dropdownItems,
      itemSelected,
      fieldSearch,
      params,
      startDate,
      endDate,
      isOpen,
      reload,
      STATUS,
      placeholderSearh: computed(() => `Buscar por ${itemSelected.value.name}`),
      formatDate: (date) => moment(date, 'YYYY/MM/DD').format('DD/MM/YYYY'),
      onNavigate: (auditing) => {
        emit('update:auditing', auditing);
        router.push({ path: `/auditorias/${auditing.id}` });
      },
    };
  },
};
</script>
<style lang="sass" scoped>
#table-auditorias
  :deep(.button).btn-edit
    border-radius: 3px
    height: 24px
    width: 24px
    padding: 0
    .icon
        margin: 0 !important
        .mdi::before
          font-size: 18px
  .tag-type
    padding: 7px 16px !important
    background: $grey-500
  .auditing-download
    gap: 7px
    :deep(.button)
      min-width: 100px
      padding: 4px
      font-size: $f-sm
      .button-wrapper
        align-items: center
        .icon
          margin: 0 !important
          .mdi::before
            font-size: $fh-xs
  .nav-search
    display: flex
    align-items: center
    justify-content: flex-start
    min-width: 100%
    margin-bottom: 32px
    :deep(.date-picker)
      .label
        margin-bottom: 0px
      .control .input
        padding-left: 34px
    :deep(.addAuditing)
      order: 2
    .search
      width: 100%
      :deep(.control) .has-addons
        display: flex
        .button
          height: 37px
        > .control
          flex-grow: 1
          input, .icon
            height: 100%

  @include until($bp-sm)
    .nav-search
      :deep(.date-picker)
        width: 100%
  @include until($bp-md)
    .nav-search
      justify-content: space-around
      flex-wrap: wrap
      .search
        order: -1
        margin-bottom: 16px
        width: 100%
      :deep(.date-picker)
        width: 48%
        .datepicker
          width: 100%
  @include from($bp-md)
    .nav-search
      padding: 0 16px
      height: 64px
      background-color: $white
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
      .search
        order: 1
        margin: auto 0 auto auto
        max-width: 507px
      :deep(.date-picker)
        margin-right: 16px
    :deep(.table)
      thead th
        padding: 13.4px 4px
      tbody td
        background-color: $white
        border-bottom: solid 1px $grey-light
        border-top: solid 1px $grey-light
        padding: 13px 3px
        text-align: center
        &:first-child
          border-left: solid 1px $grey-light
        &:last-child
          border-right: solid 1px $grey-light
          padding: 8px 16px
      .btn-show-auditoria
        height: fit-content
        .button-wrapper
          align-items: center
          > span
            white-space: pre-wrap
        font-size: $f-sm
        padding: 4px 16px
        .icon .mdi::before
          font-size: $f-sm
  @include from($bp-xl)
    :deep(.table) .btn-show-auditoria .button-wrapper  > span
      white-space: nowrap
  &.is-client :deep(.header-title)
    margin-bottom: 32px
</style>
