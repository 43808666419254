<template>
  <div class="operation-detail">
    <div class="operation-action is-flex is-flex-direction-row">
      <template v-if="data.status !== 'FINALIZADA'">
        <Button
          v-if="canDelete"
          icon-left="window-close"
          class="mr-1"
          label="Eliminar procedimiento"
          variant="danger"
          inverted
          :loading="loading.delete"
          :disabled="loading.delete"
          @click="deleteOperation"
        />
        <template v-if="isResponsible && !data.conclusion_request && data.status !== 'CREADA'">
          <p v-if="!data.conclusion_request" class="ml-auto is-flex is-align-items-center">
            <o-icon icon="inbox" />
            <span class="ml-1 has-text-weight-medium"> Sin solicitudes de término </span>
          </p>
        </template>
        <o-tooltip
          v-else-if="!data.conclusion_request && data.status !== 'CREADA'"
          :active="canAskForEnd"
          label="Archivo preliminar o final requerido"
          variant="info"
        >
          <Button
            :class="!data.accumulated_time && data.status != 'FINALIZADA' ? '' : 'ml-auto'"
            icon-left="playlist-check"
            label="Solicitar término del procedimiento"
            variant="primary"
            :disabled="canAskForEnd"
            @click="() => $emit('openModalRequest', data)"
          />
        </o-tooltip>
        <p v-else-if="data.conclusion_request" class="ml-auto is-flex is-align-items-center">
          <o-icon icon="account-clock" />
          <span class="ml-1 has-text-weight-medium"> Aprobación de término pendiente </span>
        </p>
      </template>
      <p v-else class="ml-auto is-flex is-align-items-center">
        <o-icon icon="checkbox-marked-circle" variant="primary" />
        <span class="ml-1 has-text-weight-medium"> Concepto finalizado </span>
      </p>
    </div>
    <o-tabs ref="tabsOperations" type="boxed">
      <o-tab-item>
        <template #header>
          <o-icon
            :class="hasattendedComments ? 'unattended' : ''"
            class="icon-header"
            custom-class="mdi-flip-h"
            icon="message-text-lock"
          ></o-icon>
          <span class="header-title">Comentarios internos</span>
        </template>
        <template v-if="comments.length">
          <div class="comments is-flex">
            <div class="comments-review">
              <template v-for="(comentario, key) in comments" :key="`o-00${key}`">
                <MessageCard
                  :message="comentario"
                  :position="key"
                  target="comments"
                  :on-click-action="markAsAttended"
                />
              </template>
            </div>
          </div>
        </template>
        <div
          v-else
          class="empty is-flex is-justify-content-center is-align-items-center is-flex is-justify-content-center is-align-items-center"
        >
          <h2>Sin comentarios.</h2>
        </div>
      </o-tab-item>
      <o-tab-item>
        <template #header>
          <o-icon
            :class="hasattendedObservations ? 'unattended' : ''"
            class="icon-header"
            icon="eye"
          ></o-icon>
          <span class="header-title">Observaciones</span>
        </template>
        <template v-if="observations.length">
          <div class="comments is-flex">
            <div class="comments-review">
              <template v-for="(observation, key) in observations" :key="`com-00${key}`">
                <MessageCard
                  :message="observation"
                  :position="key"
                  target="observations"
                  :on-click-action="markAsAttended"
                />
              </template>
            </div>
          </div>
        </template>
        <div
          v-else
          class="empty is-flex is-justify-content-center is-align-items-center is-flex is-justify-content-center is-align-items-center"
        >
          <h2>Sin observaciones.</h2>
        </div>
      </o-tab-item>
      <o-tab-item>
        <template #header>
          <o-icon
            :class="hasattendedRecomendations ? 'unattended' : ''"
            class="icon-header"
            icon="message-reply-text"
          ></o-icon>
          <span class="header-title">Recomendaciones</span>
        </template>
        <template v-if="recomendations.length">
          <div class="comments is-flex">
            <div class="comments-review">
              <template v-for="(observation, key) in recomendations" :key="`r-00${key}`">
                <MessageCard
                  :message="observation"
                  :position="key"
                  :on-click-action="markAsAttended"
                  target="recomendations"
                />
              </template>
            </div>
          </div>
        </template>
        <div
          v-else
          class="empty is-flex is-justify-content-center is-align-items-center is-flex is-justify-content-center is-align-items-center"
        >
          <h2>Sin recomendaciones.</h2>
        </div>
      </o-tab-item>
      <o-tab-item :disabled="isConclusionsDisabled">
        <template #header>
          <o-icon
            :class="hasattendedConclusions ? 'unattended' : ''"
            class="icon-header"
            icon="dots-horizontal-circle"
          ></o-icon>
          <span class="header-title">Conclusiones</span>
        </template>
        <div class="comments is-flex">
          <div class="comments-review">
            <template v-for="(observation, key) in conclusions" :key="`co-00${key}`">
              <MessageCard
                v-if="observation.message"
                :message="observation"
                :position="key"
                target="conclusions"
              />
            </template>
          </div>
        </div>
      </o-tab-item>
    </o-tabs>
    <template v-if="data.status !== 'FINALIZADA'">
      <div v-if="allowConlusionResposible || allowActionAuditor" class="text-input">
        <template v-if="allowConlusionResposible">
          <p class="has-text-weight-semibold has-text-centered mb-3 fh-xs">
            ¿Qué acción desea realizar?
          </p>
          <div class="aprove-handler mb-4">
            <Button
              :outlined="aproveHandler.current !== 'REJECT'"
              size="small"
              variant="danger"
              label="Rechazar"
              @click="changeAction('REJECT')"
            />
            <Button
              :outlined="aproveHandler.current !== 'APROVE'"
              size="small"
              variant="primary"
              label="Aprobar"
              @click="changeAction('APROVE')"
            />
          </div>
        </template>
        <template
          v-if="
            (allowConlusionResposible && aproveHandler.current) ||
            currentTab.service !== 'conclusions'
          "
        >
          <h2 class="mb-3 has-text-weight-bold">Hacer {{ buttonController.field }}:</h2>
          <Field
            v-model="newMessage"
            :placeholder="`Escribir ${buttonController.field}`"
            :disabled="loading.send"
            type="textarea"
            maxlength="500"
          />
          <div class="is-flex sendComments">
            <o-tooltip
              :active="isPreliminarMissing"
              label="Archivo preliminar requerido"
              variant="info"
            >
              <Button
                :variant="buttonController.variant"
                :label="buttonController.label"
                :loading="loading.send"
                :disabled="allowDisabledAprovationButton"
                @click.prevent="
                  currentTab.service !== 'conclusions'
                    ? sendMessage(currentTab.service)
                    : respondTerminationRequest()
                "
              />
            </o-tooltip>
            <span class="ml-auto counter-field">{{ textLength }} / 500</span>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
import { Button, Field, MessageCard } from '@/components';
import { useAuthStore } from '@/store';
import ModalConfirm from '@/components/modals/ModalConfirm.vue';
import { useComponentUtils } from '@/components/conf/composables';
import moment from 'moment';
import { MESSAGES_SERVICES } from '@/config/constants';
import { useProgrammatic } from '@oruga-ui/oruga-next';
import { useContabilidad } from '@/utils/composables';
import {
  onBeforeUnmount,
  reactive,
  ref,
  computed,
  getCurrentInstance,
  onBeforeMount,
  watch,
  onMounted,
  toRefs,
} from 'vue';
export default {
  components: {
    Button,
    Field,
    MessageCard,
  },
  props: {
    data: { type: [Object, Array], default: () => {} },
    isResponsible: { type: Boolean, default: false },
  },
  emits: ['openModalRequest', 'update:operation-data', 'reload:auditing'],
  setup(props, { emit }) {
    const { COMMENTS, OBSERVATIONS, RECOMENDATIONS, CONCLUSIONS } = MESSAGES_SERVICES;
    const { getAUTH } = useAuthStore();
    const { proxy } = getCurrentInstance();
    const { oruga } = useProgrammatic();
    const Api = proxy?.Api;
    const { data } = toRefs(props);
    const { Normalize } = useComponentUtils();
    const { INDICATOR } = useContabilidad();
    const loading = reactive({ send: false, delete: false });
    const tabsOperations = ref({});
    const aproveHandler = reactive({ inputlabel: '', current: false });
    const isModalActive = ref(false);
    const observations = ref([]);
    const comments = ref([]);
    const recomendations = ref([]);
    const conclusions = ref([]);
    const newMessage = ref('');
    const handleDelete = async () => {
      const { concept_id, id } = props.data;
      await Api.delete(`/concept/${concept_id}/operation/${id}`);
    };
    const confirmDeleteOperation = async () => {
      const instance = oruga.modal.open({
        component: ModalConfirm,
        canCancel: false,
        props: {
          action: handleDelete,
          title: `¿Estás seguro de eliminar el procedimiento?`,
          message: `El procedimiento <b>"${props.data.description}"</b> será <b>eliminado</b>.`,
          needReloadTable: true,
          cleanReload: true,
        },
        trapFocus: true,
      });
      return instance.promise;
    };

    const currentTab = computed(() => {
      const currentId = tabsOperations.value ? tabsOperations.value.activeId : 1;
      let _currentTab = { name: 'comentarios', service: COMMENTS };
      switch (currentId) {
        case 1:
          _currentTab.name = 'comentario interno';
          _currentTab.service = COMMENTS;
          break;
        case 2:
          _currentTab.name = 'observación';
          _currentTab.service = OBSERVATIONS;
          break;
        case 3:
          _currentTab.name = 'recomendación';
          _currentTab.service = RECOMENDATIONS;
          break;
        case 4:
          _currentTab.name = 'conclusión';
          _currentTab.service = CONCLUSIONS;
          break;
      }
      return _currentTab;
    });

    const prepareMessage = (is_attended = 0) => {
      const { full_name, id } = getAUTH;
      return {
        user_id: id,
        user_name: full_name,
        message: Normalize(newMessage.value, {
          replace: { find: ['\\n'], value: [' '] },
        }),
        date: moment(new Date()).format('DD/MM/YYYY'),
        is_attended,
      };
    };
    const sendMessage = async (service) => {
      loading.send = true;
      try {
        const { data } = props;
        let prevMessages = [];
        if (service === OBSERVATIONS) prevMessages = observations.value;
        else if (service === RECOMENDATIONS) prevMessages = recomendations.value;
        else if (service === COMMENTS) prevMessages = comments.value;

        const message = prepareMessage();
        const messageData = [...prevMessages, message];

        await Api.patch(`/concept/${data.concept_id}/operation/${data.id}/${service}`, {
          [service]: JSON.stringify(messageData),
        });
        newMessage.value = '';
        const newOperationData = { id: data.id };
        newOperationData[service] = JSON.stringify(messageData);
        emit('update:operation-data', newOperationData.id);
        prevMessages.push(message);
      } catch (error) {
        console.log(error);
      }
      loading.send = false;
    };
    const respondTerminationRequest = async () => {
      loading.send = true;
      try {
        const { data } = props;
        const { VERDE, AMARILLO, ROJO } = INDICATOR;
        const bodyRequest = {};
        conclusions.value.forEach((_conlusion) => (_conlusion.is_attended = 1));
        let _indicator = VERDE.value;
        if (aproveHandler.current === 'APROVE') {
          const _conclusions = [...conclusions.value, prepareMessage(1)];
          bodyRequest.conclusions = JSON.stringify(_conclusions);
          bodyRequest.status = 'FINALIZADA';
          if (recomendations.value.length) _indicator = AMARILLO.value;
          else if (observations.value.length) _indicator = ROJO.value;
          emit('update:operation-data', {
            id: data.id,
            indicator: _indicator,
          });
        } else {
          bodyRequest.status = 'EN PROGRESO';
          bodyRequest.conclusions = JSON.stringify([...conclusions.value]);
          await sendMessage(RECOMENDATIONS);
          if (tabsOperations.value) tabsOperations.value.activeId = 3;
        }
        await Api.patch(
          `/concept/${data.concept_id}/operation/${data.id}/conclusions`,
          bodyRequest,
        );
        emit('update:operation-data', {
          id: data.id,
          ...bodyRequest,
        });
        await Api.put(`/concept/${data.concept_id}/operation/${data.id}`, {
          conclusion_request: 0,
        });
        emit('update:operation-data', {
          id: data.id,
          conclusion_request: 0,
        });
        newMessage.value = '';
        if (bodyRequest.status === 'FINALIZADA') emit('reload:auditing');
      } catch (error) {
        console.log(error);
      }
      loading.send = false;
    };
    const markAsAttended = async (position, target, setAttended) => {
      try {
        const { data } = props;
        let targetMessages = [];
        if (target === OBSERVATIONS) targetMessages = observations.value;
        else if (target === RECOMENDATIONS) targetMessages = recomendations.value;
        else if (target === CONCLUSIONS) targetMessages = conclusions.value;
        else if (target === COMMENTS) targetMessages = comments.value;
        if (!targetMessages[position].is_attended) {
          setAttended();
          targetMessages[position].is_attended = 1;
          await Api.patch(`/concept/${data.concept_id}/operation/${data.id}/${target}`, {
            [target]: JSON.stringify(targetMessages),
          });
          emit('update:operation-data', {
            id: data.id,
            [target]: JSON.stringify(targetMessages),
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    const deleteOperation = async () => {
      loading.delete = true;
      try {
        const { action } = await confirmDeleteOperation();
        if (action !== 'yes') throw '';
      } catch (error) {
        console.log(error);
      }
      loading.delete = false;
    };
    const buttonController = computed(() => {
      const properties = {};
      if (allowConlusionResposible.value && currentTab.value.service === 'conclusions') {
        if (aproveHandler.current === 'APROVE') {
          properties.label = 'Aprobar término';
          properties.variant = 'primary';
          properties.field = 'conclusión';
        } else {
          properties.label = 'Rechazar término';
          properties.variant = 'danger';
          properties.field = 'recomendación';
        }
      } else {
        properties.label = `Enviar ${currentTab.value.name}`;
        properties.variant = 'dark';
        properties.field = currentTab.value.name;
      }
      return properties;
    });
    const allowConlusionResposible = computed(
      () =>
        props.isResponsible &&
        props.data.conclusion_request &&
        currentTab.value.service === CONCLUSIONS,
    );
    const isPreliminarMissing = computed(() => {
      return (
        !!data.value.conclusion_request &&
        !documents.value.PRELIMINAR.exist &&
        aproveHandler.current !== 'REJECT'
      );
    });
    const allowDisabledAprovationButton = computed(
      () =>
        loading.send ||
        isPreliminarMissing.value ||
        (newMessage.value == '' && aproveHandler.current != 'APROVE'),
    );
    const allowActionAuditor = computed(
      () =>
        (!props.isResponsible && !props.data.conclusion_request) ||
        (currentTab.value.service !== CONCLUSIONS && !props.data.conclusion_request),
    );
    const hasattendedObservations = computed(
      () => observations.value.filter((m) => m.is_attended == 0).length,
    );
    const hasattendedRecomendations = computed(
      () => recomendations.value.filter((m) => m.is_attended == 0).length,
    );
    const hasattendedConclusions = computed(
      () => conclusions.value.filter((m) => m.is_attended == 0).length,
    );
    const hasattendedComments = computed(
      () => comments.value.filter((m) => m.is_attended == 0).length,
    );
    const canDelete = computed(() => {
      const { data } = props;
      return (
        !data.accumulated_time &&
        !data.conclusion_request &&
        !observations.value.length &&
        !recomendations.value.length &&
        !conclusions.value.length &&
        !comments.value.length
      );
    });
    const canAskForEnd = computed(() => {
      const { PRELIMINAR, FINAL } = documents.value;
      return PRELIMINAR.exist == false && FINAL.exist == false;
    });
    const isConclusionsDisabled = computed(() => !conclusions.value.length);
    const documents = computed(() => {
      const _documents = JSON.parse(data.value.file_name);
      const types = Object.keys(_documents);
      const hasPreliminar = types.includes('PRELIMINAR');
      const hasFinal = types.includes('FINAL');
      return {
        PRELIMINAR: {
          key: 'PRELIMINAR',
          name: hasPreliminar ? _documents.PRELIMINAR : '',
          exist: hasPreliminar,
        },
        FINAL: {
          key: 'FINAL',
          name: hasPreliminar ? _documents.FINAL : '',
          exist: hasFinal,
        },
      };
    });
    const changeAction = (value) => {
      aproveHandler.current = value;
    };
    const dataParse = (_service = 'ALL') => {
      if (_service === 'ALL' || _service === COMMENTS)
        comments.value = JSON.parse(props.data.comments);
      if (_service === 'ALL' || _service === OBSERVATIONS)
        observations.value = JSON.parse(props.data.observations);
      if (_service === 'ALL' || _service === RECOMENDATIONS)
        recomendations.value = JSON.parse(props.data.recomendations);
      if (_service === 'ALL' || _service === CONCLUSIONS)
        conclusions.value = JSON.parse(props.data.conclusions);
    };

    onBeforeMount(dataParse);
    onMounted(() => {
      if (props.data.status === 'FINALIZADA' || props.data.conclusion_request)
        tabsOperations.value.activeId = 4;
    });
    watch(newMessage, (value) => {
      if (value.length)
        window.onbeforeunload = () => {
          return (event.returnValue = 'Te estás saliendo del sitio…');
        };
      else window.onbeforeunload = null;
    });
    watch(
      () => data.value.comments,
      () => dataParse('comments'),
    );
    watch(
      () => data.value.observations,
      () => dataParse('observations'),
    );
    watch(
      () => data.value.recomendations,
      () => dataParse('recomendations'),
    );
    watch(
      () => data.value.conclusions,
      () => dataParse('conclusions'),
    );
    watch(
      () => data.value.conclusion_request,
      (value) => {
        if (value) tabsOperations.value.activeId = 4;
      },
    );
    watch(currentTab, () => (newMessage.value = ''));

    onBeforeUnmount(() => {
      newMessage.value = '';
      window.onbeforeunload = null;
    });
    return {
      allowActionAuditor,
      allowConlusionResposible,
      allowDisabledAprovationButton,
      aproveHandler,
      buttonController,
      canAskForEnd,
      canDelete,
      changeAction,
      comments,
      conclusions,
      currentTab,
      deleteOperation,
      documents,
      hasattendedComments,
      hasattendedConclusions,
      hasattendedObservations,
      hasattendedRecomendations,
      isConclusionsDisabled,
      isModalActive,
      loading,
      markAsAttended,
      newMessage,
      observations,
      isPreliminarMissing,
      recomendations,
      respondTerminationRequest,
      sendMessage,
      tabsOperations,
      textLength: computed(() => newMessage.value.length),
    };
  },
};
</script>
<style lang="sass" scoped>
.icon-header
  position: relative
  &.unattended::before
    display: block
    border-radius: 10px
    position: absolute
    content: ' '
    top: 1px
    right: 1px
    width: 8px
    height: 8px
    background: $danger
    z-index: 1
.operation-detail
  display: grid
  position: relative
  column-gap: 10px
  min-height: 426px
  grid-template-columns:  minmax(auto,  66%) minmax(auto, 34%)
  z-index: 0
  .operation-action
    position: absolute
    justify-content: space-between
    right: 0
    p
      color: $grey
    @include until($bp-lg)
      justify-content: flex-end
      > :last-child
        margin-left: 10px
  .text-input
    .aprove-handler
      :deep(.button)
        width: 50%
        &.is-outlined
          &:not(:hover)
            background: none
            &.is-primary
              color: $primary
      & > :first-child
        font-size: $f-xss
        border-radius: 10px 0 0 10px
      & > :last-child
        font-size: $f-xss
        border-radius: 0 10px 10px 0
    :deep(.button)
      min-width: 154px
  :deep(.b-tabs)
    margin-bottom: 0
    .tabs.is-boxed div a.is-active
      background-color: unset !important
    .tab-content
      height: calc( 100% - 40px )
      max-height: 316px
      padding: 16px 0
      padding-bottom: 0
      .tab-item
        height: 100%
        .comments
          justify-content: space-between
          :deep(.o-load)
            min-width: unset
          .comments-review
            width: 100%
            max-height: 383px
        @include until($bp-sm)
          .comment
            min-width: unset
            width: 100%
            max-width: unset
        @include until($bp-md)
          .comments-review
            max-height: 363px !important
            .comment
              min-width: 250px
  .empty
    color: $grey-dark
    font-size: $fh-md
    display: flex
    align-items: center
    justify-content: center
    text-align: center
    height: 100%
    width: 100%
    margin: auto
    h2
      font-weight: 600
  @include until($bp-lg)
    display: flex
    flex-direction: column
    .operation-action
      position: static
      width: 100%
      flex-wrap: wrap
      margin-bottom: 16px
      > :first-child
        margin-bottom: 8px
@include until($bp-lg)
.text-input
  z-index: 1
  margin-top: 57px
</style>
