<template>
  <o-field
    :class="required ? 'is-required' : ''"
    :label="label"
    v-bind="fieldProps"
    :message="errorMessage"
    :variant="fieldProps.variant ? fieldProps.variant : errorMessage ? 'danger' : undefined"
  >
    <o-skeleton v-if="skeleton" animated height="35px" size="large" />
    <template v-else>
      <template v-if="$slots.addonsLeft">
        <slot name="addonsLeft" />
      </template>
      <template v-if="$slots.default">
        <slot name="default" />
      </template>
      <o-input
        v-else
        v-bind="inputProps"
        ref="input"
        v-model="inputValue"
        class="text-transform"
        :class="textTransform ? `${textTransform}` : ''"
        :disabled="disabled"
        :required="required"
        @blur="onBlur"
        @maska="onMaska"
      />
      <template v-if="$slots.addonsRight">
        <slot name="addonsRight" />
      </template>
    </template>
  </o-field>
</template>
<script>
import { ref, toRefs, watch } from 'vue';
import { usePickProps } from './conf/composables';
export default {
  props: {
    label: { type: String, default: '' },
    errorMessage: { type: String, default: '' },
    modelValue: { type: [String, Number], default: '' },
    required: { type: Boolean, default: false },
    disabled: { type: [Number, Boolean], default: false },
    skeleton: { type: Boolean, default: false },
    valueInterceptor: { type: Function, default: null },
    textTransform: { type: String, enum: ['lower', 'upper', 'capitalize'], default: '' },
  },
  emits: ['update:model-value', 'maska', 'blur'],
  setup(props, { attrs, emit }) {
    const { propsPicked: fieldProps } = usePickProps('FieldProps', { props, attrs });
    const { propsPicked: inputProps } = usePickProps('InputProps', { props, attrs });
    const { modelValue } = toRefs(props);
    const inputValue = ref(props.modelValue);
    const input = ref(null);
    const textTransformTypes = {
      LOWER: 'lower',
      UPPER: 'upper',
      CAPITALIZE: 'capitalize',
    };

    const formatText = (value) => {
      const { UPPER, LOWER, CAPITALIZE } = textTransformTypes;
      let newFormat = value ? value : '';
      switch (props.textTransform) {
        case UPPER:
          newFormat = newFormat.toUpperCase();
          break;
        case LOWER:
          newFormat = newFormat.toLowerCase();
          break;
        case CAPITALIZE:
          newFormat = newFormat
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
          break;
      }
      if (attrs.type === 'number' && value.length) newFormat = Number(value);
      return newFormat;
    };

    const onMaska = (e) => emit('maska', e);
    const onBlur = (e) => {
      if (!e.target.value.trim().length) emit('update:model-value', '');
      else inputValue.value = formatText(e.target.value);
      emit('blur', e);
    };

    watch(modelValue, (value) => {
      if (props.valueInterceptor) inputValue.value = props.valueInterceptor(value);
      else inputValue.value = value;
    });
    watch(inputValue, (value) => {
      if (value && !value.toString().trim().length) emit('update:model-value', '');
      else emit('update:model-value', value);
    });
    return {
      input,
      onMaska,
      onBlur,
      fieldProps,
      inputProps,
      inputValue,
      textTransformTypes,
    };
  },
};
</script>
<style scoped lang="sass">
:deep(.text-transform)
  &.capitalize
    text-transform: capitalize
  &.upper
    text-transform: uppercase
  &.lower
    text-transform: lowercase
.field.is-required >
  :deep(.label)
    &::after
      color: $color-radical-red
      content: '*'
.field.is-required :deep(.field-label) > .label
  &::after
    color: $color-radical-red
    content: '*'
</style>
