<template>
  <Table
    id="table-user-associates"
    ref="tableUserAssociates"
    v-model:selected="selected"
    v-model:loading="isLoading"
    v-model:data="tableData"
    paginated
    :per-page="6"
    sticky-header
    :api-url="apiUrl"
  >
    <o-table-column
      v-slot="{ row }"
      field="name"
      label="Nombre usuario"
      :td-attrs="() => ({ class: 'is-uppercase' })"
    >
      {{ row.name }}
    </o-table-column>
    <o-table-column
      v-slot="{ row }"
      field="email"
      label="Email"
      width="206"
      :td-attrs="() => ({ class: 'has-text-centered is-uppercase break-space' })"
    >
      {{ row.email }}
    </o-table-column>
    <o-table-column v-slot="{ row }" field="services" label="Servicios asignados" width="206">
      <div class="is-flex w-100">
        <Tag
          v-if="JSON.parse(row.associate_services) && existTag(row.associate_services, 'Auditoria')"
          class="mr-auto"
          label="Audit."
          rounded
          variant="link"
        />
        <Tag
          v-if="
            JSON.parse(row.associate_services) && existTag(row.associate_services, 'Contabilidad')
          "
          class="ml-auto"
          label="Cont."
          rounded
          variant="purple"
        />
      </div>
    </o-table-column>
    <o-table-column
      v-slot="{ row }"
      field="email"
      label="Acciones"
      width="100"
      :th-attrs="() => ({ class: 'px-2' })"
    >
      <div class="user-actions">
        <Button variant="primary" icon-right="pencil" @click="onEdit(row)" />
        <Button class="ml-2" variant="danger" icon-right="delete" @click="onDelete(row)" />
      </div>
    </o-table-column>
  </Table>
</template>
<script>
import { ref, toRefs, watch } from 'vue';
import { Table, Button, Tag } from '@/components';
export default {
  components: {
    Button,
    Table,
    Tag,
  },
  props: {
    loading: { type: Boolean, default: false },
    customerId: { type: String, default: null },
  },
  emits: ['on-edit-associate', 'onDeleteAssociate', 'update:loading'],
  setup(props, { emit }) {
    const apiUrl = ref(`clients/${props.customerId}/associates`);
    const { loading } = toRefs(props);
    const isLoading = ref(props.loading);
    const selected = ref({});
    const tableData = ref([]);
    const tableUserAssociates = ref(null);

    const onEdit = (row) => {
      selected.value = row;
      emit('on-edit-associate', { ...row });
    };
    const onDelete = (row) => {
      selected.value = row;
      emit('onDeleteAssociate', { ...row });
    };
    const existTag = (services, target) => {
      if (!services) return null;
      return JSON.parse(services).find((service) => service.name === target);
    };
    const reload = async () => {
      if (tableUserAssociates.value) {
        await tableUserAssociates.value.reload(false);
      }
    };

    watch(isLoading, (value) => emit('update:loading', value));
    watch(loading, (value) => (isLoading.value = value));
    return {
      // data
      apiUrl,
      isLoading,
      selected,
      tableData,
      tableUserAssociates,

      //   method
      existTag,
      onEdit,
      onDelete,
      reload,
    };
  },
};
</script>
<style lang="sass" scoped>
#table-user-associates :deep(.b-table)
    .table:focus
        border-color: unset
        box-shadow: unset
        border: none
    .table-wrapper
        min-height: 206px
        tr.is-selected
           background-color:  #C7DAE5
        > .o-load.loading
            min-width: unset
.user-actions
    display: flex
    align-items: center
    justify-content: center
    margin: auto
    :deep(.button)
        border-radius: 3px
        height: 24px
        width: 24px
        padding: 0
        .icon
            margin: 0 !important
            .mdi::before
              font-size: 18px
</style>
