<template>
  <template v-if="hasPermission">
    <Field
      class="custome-select"
      :label="label"
      :required="required"
      :horizontal="horizontal"
      :message="errorMessage"
      :variant="errorMessage ? 'danger' : undefined"
    >
      <o-skeleton v-if="isLoading" animated height="40px" size="large" />
      <o-select
        v-else
        v-model="select"
        v-bind="selectProps"
        :disabled="isLoading || disabled"
        icon-right="menu-down"
        :required="required"
        @blur="$emit('blur', $event)"
      >
        <option v-for="(item, key) in dataObject" :key="key" :value="item[model]">
          {{ item[model] }}
        </option>
      </o-select>
    </Field>
  </template>
</template>
<script>
import { Field } from '@/components';
import { PermissionValidator } from '@/utils/Secure';
import { usePickProps } from './conf/composables';
import { computed, ref, toRefs, watch, getCurrentInstance, onMounted } from 'vue';
export default {
  components: {
    Field,
  },
  props: {
    apiUrl: { type: [String, Function], default: '' },
    params: { type: Array, default: () => [] },
    model: { type: String, default: 'name' },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    label: { type: String, default: '' },
    permission: { type: Object, default: null },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    dataPreProcesor: { type: Function, default: null },
    modelValue: { type: String, default: null },
    required: { type: Boolean, default: false },
    selected: { type: Object, default: () => {} },
    horizontal: { type: Boolean, default: false },
    errorMessage: { type: String, default: undefined },
  },
  emits: [
    'update:model-value',
    'update:message',
    'update:variant',
    'update:data',
    'on-select',
    'update:loading',
    'blur',
  ],
  setup(props, { attrs, emit }) {
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const { propsPicked: selectProps } = usePickProps('SelectProps', { props, attrs });
    const { params, loading } = toRefs(props);
    const isLoading = ref(props.loading);
    const dataSelect = toRefs(props).data;
    const dataObject = ref(props.data);

    const select = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit(
          'on-select',
          dataObject.value.find((item) => item[props.model] === value),
        );
        emit('update:model-value', value);
      },
    });

    const getData = async (clear) => {
      isLoading.value = true;
      try {
        if (props.apiUrl) {
          let { data } = await Api.get(`${props.apiUrl}${queryParams.value}`);
          if (props.dataPreProcesor) dataObject.value = props.dataPreProcesor(data);
          else dataObject.value = data;
          if (clear) data.value = [];
        }
      } catch (error) {
        console.log(error);
      }
      isLoading.value = false;
    };

    const queryParams = computed(() => {
      const aParams = [...params.value];
      return `?${aParams.join('&')}`;
    });
    const hasPermission = computed(() => {
      if (!props.permission || props.permission === true) return true;
      return PermissionValidator(props.permission);
    });

    watch(dataObject, (value) => emit('update:data', value));
    watch(dataSelect, (value) => (dataObject.value = value));
    watch(params, (newVals, oldVals) => {
      if (newVals.join(',') === oldVals.join(',')) return;
      getData(true);
    });
    watch(loading, (value) => (isLoading.value = value));
    watch(isLoading, (value) => emit('update:loading', value));
    watch(select, (value) => {
      if (value?.length && dataObject.value?.length)
        emit(
          'on-select',
          dataObject.value.find((item) => item[props.model] === value),
        );
    });
    onMounted(getData);
    return {
      selectProps,
      select,
      dataObject,
      isLoading,
      hasPermission,
    };
  },
};
</script>
<style lang="sass" scoped>
.custome-select
  &.is-horizontal
    :deep(.field-label)
      display: flex
      align-items: center
      margin-right: 10px
      .label
        white-space: nowrap
        margin-bottom: 0

:deep(.label)
    font-family: 'Poppins','Arial','Brush Script MT'
    color: $grey
.select
  width: 100%
  :deep(select)
    width: 100%
    &.is-empty
      opacity: 0.5
  &::after
      content: none !important
</style>
