<template>
  <div :class="classes">
    <o-icon icon="circle" size="23" :variant="customeColor" />
    <p>{{ label }}</p>
  </div>
</template>
<script>
import { computed, toRefs } from 'vue';
export default {
  props: {
    status: {
      type: String,
      enum: ['done', 'doing', 'toDo', 'stoped', 'needAtention'],
      default: null,
    },
    label: { type: String, default: '' },
    indicator: { type: String, enum: ['VERDE', 'AMARILLO', 'ROJO'], default: 'ROJO' },
  },
  setup(props) {
    const { status } = toRefs(props);
    const customeColor = computed(() => {
      return props.indicator ? props.indicator : null;
    });
    return {
      classes: computed(() => {
        let classes = ['traffic-light'];
        if (status.value) classes.push(`is-${status.value}`);
        return classes.join(' ');
      }),
      customeColor,
    };
  },
};
</script>
<style lang="sass" scoped>
.traffic-light
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    p
        font-weight: 500
        font-size: 16px
        color: #000000
        text-transform: uppercase
    .has-text-VERDE
      color: $success-light !important
    .has-text-AMARILLO
      color: $color-bright-sun !important
    .has-text-ROJO
      color: $color-radical-red !important
    @each $className, $object in $monthlyTrafficLight
        &.is-#{$className}
            :deep(.icon) i::before
                color: map-get($object, 'color')
</style>
