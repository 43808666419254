<template>
  <Modal
    id="clientModal"
    v-model:is-open="isModalActive"
    :button-label="modalController.saveLabel"
    :header="modalController.header"
    size="is-large"
    :loading="loading"
    :is-disabled="isDisabledModal"
    :save-disabled="saveDisabled"
    @cancel="resetForm"
    @save="onSubmit"
  >
    <div class="form-container">
      <form ref="form">
        <Field
          v-model="name"
          maxlength="255"
          class="w-100"
          label="Nombre:"
          required
          :error-message="errors.name"
          v-bind="nameAttrs"
          placeholder="Nombre completo"
          text-transform="upper"
        />
        <Field
          v-model="email"
          label="Correo:"
          placeholder="correo@ejemplo.com"
          maxlength="255"
          :error-message="errors.email"
          v-bind="emailAttrs"
          type="email"
        />
        <Field
          v-model="tin"
          label="RFC:"
          placeholder="AAAA000000A00"
          maxlength="13"
          text-transform="upper"
          required
          :error-message="errors.tin"
          v-bind="tinAttrs"
        />
        <Select
          v-model="tax_regime_name"
          v-bind="taxRegimeAttrs"
          :error-message="errors.tax_regime_name"
          label="Régimen fiscal"
          api-url="/tax-regime"
          class="w-100"
          placeholder="Régimen de las actividades empresariales con ingresos a través de plataformas tecnológicas"
          required
          @on-select="setTaxRegimeId($event)"
        />
        <Select
          v-model="entity_type"
          :disabled="entityTypes.length <= 1"
          :data="entityTypes"
          v-bind="entityTypeAttrs"
          placeholder="Selecciona un elemento"
          :error-message="errors.entity_type"
          label="Persona"
          required
        />
        <Field
          v-model="phone_number"
          v-maska="phoneNumberMaska"
          v-bind="phoneNumberAttrs"
          label="Teléfono"
          placeholder="(111)-222-3333"
          data-maska="(###)-###-####"
          pattern="(^\([0-9]{3}\)-[0-9]{3}-[0-9]{4}$)"
          :maxlength="14"
          required
        />
        <div class="is-flex is-flex-wrap-wrap is-justify-content-center w-100">
          <CheckBox
            v-model="is_active_bills"
            v-bind="isActiveBillsAttrs"
            class="mr-auto"
            label="Requiere control de pago (Facturación)"
            root-class="radio-document-type"
          />
          <Switch
            v-if="data.id"
            v-model="is_active"
            class="ml-auto"
            v-bind="isActiveAttrs"
            horizontal
            :disabled="hasServicesActive"
            :label="is_active ? 'Cliente activo' : 'Cliente inactivo'"
          />
          <p v-if="hasServicesActive" class="warning-flag ml-auto">
            No es posible desactivar clientes con servicios en proceso
          </p>
        </div>
        <h3 class="w-100 divider has-text-weight-bold fh-sm has-text-dark is-uppercase">
          Servicios
        </h3>
        <div class="is-flex is-flex-wrap-wrap is-justify-content-center w-100">
          <div class="w-100 mb-2 is-justify-content-flex-end is-flex">
            <p v-if="!is_active" class="warning-flag ml-auto">
              No se puede activar servicios a clientes inactivos
            </p>
          </div>
          <CheckBox
            v-model="isActiveAuditing"
            :disabled="hasAuditingInfo || !is_active"
            v-bind="isActiveAuditingAttrs"
            class="mr-auto"
            root-class="radio-document-type"
            :label="userServices.auditoria.name"
          />
          <p v-if="hasAuditingInfo" class="warning-flag ml-auto">
            No se puede deshabilitar mientras haya una auditoría activa
          </p>
          <CheckableDropdown
            v-if="isActiveAuditing"
            v-model:selected-options="auditingResponsible"
            :disabled="!is_active"
            :error-message="errors.auditingResponsible"
            v-bind="AuditingResponsibleAttrs"
            searchable
            position="bottom-left"
            model="full_name"
            :api="{
              url: 'users',
              params: checablesDropdownParams.auditoria,
            }"
            horizontal
            placeholder="Selecciona responsable(s)"
            class="responsables-dropdown mr-auto mt-1"
            label="Responsable(s)"
          />
        </div>
        <div class="is-flex is-flex-wrap-wrap is-justify-content-center w-100">
          <CheckBox
            v-model="isActiveContabilidad"
            :disabled="hasContabilidadInfo || !is_active"
            v-bind="isActiveContabilidadAttrs"
            class="mr-auto"
            root-class="radio-document-type"
            :label="userServices.contabilidad.name"
          />
          <p v-if="hasContabilidadInfo" class="warning-flag ml-auto">
            No se puede deshabilitar mientras haya una contabilidad activa
          </p>
          <CheckableDropdown
            v-if="isActiveContabilidad"
            v-model:selected-options="contabilidadResponsible"
            :disabled="!is_active"
            :error-message="errors.contabilidadResponsible"
            v-bind="contabilidadResponsibleAttrs"
            searchable
            position="bottom-left"
            model="full_name"
            :api="{
              url: 'users',
              params: checablesDropdownParams.contabilidad,
            }"
            horizontal
            placeholder="Selecciona responsable(s)"
            class="responsables-dropdown mr-auto mt-1"
            label="Responsable(s)"
          />
        </div>
      </form>
    </div>
  </Modal>
</template>
<script setup>
import { Modal, Field, Select, Switch, CheckableDropdown, CheckBox } from '@/components';
import { userRoles } from '@/config/constants';
import { ref, watch, toRefs, computed, getCurrentInstance, reactive, onMounted } from 'vue';
import useDialog from '@/utils/composables/useDialog';
import { schemas } from '@/utils/YupSchemas';
import { useForm } from 'vee-validate';
import { useRouter } from 'vue-router';
import * as yup from 'yup';

const props = defineProps({
  isOpen: { type: Boolean, default: false },
  data: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(['update:active', 'update:is-open', 'update:data', 'success:save']);
const { proxy } = getCurrentInstance();
const Api = proxy?.Api;
const { Notify } = useDialog();
const router = useRouter();

// DATA
const { isOpen, data: clientData } = toRefs(props);
const isModalActive = ref(props.isOpen);
const phoneNumberMaska = reactive({});
const form = ref(null);
const entityTypesData = {
  physical: { key: 'physical', name: 'PERSONA FISICA' },
  legal: { key: 'legal', name: 'PERSONA MORAL' },
};
const entityTypes = ref([]);
const userServices = reactive({
  auditoria: {
    key: 'auditoria',
    id: null,
    clientServiceId: null,
    name: 'Auditoria',
    is_load_info: false,
    isActive: false,
    fieldPath: 'isActiveAuditing',
    responsiblesId: [],
  },
  contabilidad: {
    key: 'contabilidad',
    id: null,
    clientServiceId: null,
    name: 'Contabilidad',
    is_load_info: false,
    isActive: false,
    fieldPath: 'isActiveContabilidad',
    responsiblesId: [],
  },
});

// FORM
const {
  errors,
  defineField,
  resetForm,
  setValues,
  values,
  handleSubmit,
  isSubmitting,
  schema,
  meta,
} = useForm({
  initialValues: {
    auditingResponsible: [],
    contabilidadResponsible: [],
    isActiveAuditing: false,
    isActiveContabilidad: false,
    is_active: true,
  },
  validationSchema: yup.object({
    email: schemas.emailSchema,
    name: schemas.clientNameSchema,
    tin: schemas.tinSchema,
    tax_regime_name: schemas.selectSchema,
    entity_type: schemas.entityTypeSchema,
    phone_number: schemas.phoneNumberSchema,
    is_active_bills: schemas.BooleanSchema,
    is_active: schemas.BooleanSchema,
    isActiveAuditing: schemas.BooleanSchema,
    isActiveContabilidad: schemas.BooleanSchema,
    auditingResponsible: yup.array(),
    contabilidadResponsible: yup.array(),
  }),
});
const loading = reactive({ save: isSubmitting, services: false, clientServices: false });
const [email, emailAttrs] = defineField('email');
const [name, nameAttrs] = defineField('name');
const [entity_type, entityTypeAttrs] = defineField('entity_type');
const [tin, tinAttrs] = defineField('tin', {
  validateOnChange: false,
  validateOnModelUpdate: false,
});
const [tax_regime_name, taxRegimeAttrs] = defineField('tax_regime_name');
const [phone_number, phoneNumberAttrs] = defineField('phone_number');
const [is_active_bills, isActiveBillsAttrs] = defineField('is_active_bills');
const [is_active, isActiveAttrs] = defineField('is_active');
const [isActiveAuditing, isActiveAuditingAttrs] = defineField('isActiveAuditing');
const [auditingResponsible, AuditingResponsibleAttrs] = defineField('auditingResponsible');
const [isActiveContabilidad, isActiveContabilidadAttrs] = defineField('isActiveContabilidad');
const [contabilidadResponsible, contabilidadResponsibleAttrs] =
  defineField('contabilidadResponsible');

const auditingResponsibleRules = computed(() =>
  isActiveAuditing.value
    ? yup.array().min(1, 'Necesita seleccionar mínimo un responsable')
    : yup.array().min(0),
);
const contabilidadResponsibleRules = computed(() =>
  isActiveContabilidad.value
    ? yup.array().min(1, 'Necesita seleccionar mínimo un responsable')
    : yup.array().min(0),
);

// METHODS
const setEntityTypes = ({ physical: _physical, legal: _legal }) => {
  const { legal, physical } = entityTypesData;
  entityTypes.value = [];
  if (_physical) entityTypes.value.push(physical);
  if (_legal) entityTypes.value.push(legal);
  const currentValue = entityTypesData[_legal ? legal.key : physical.key].name;
  if (_physical || _legal)
    setValues({
      entity_type: _physical && _legal ? undefined : currentValue,
    });
};
const setTaxRegimeId = (event) => {
  const { id, physical, legal } = event;
  setEntityTypes({ physical, legal });
  setValues({
    tax_regime_id: id,
  });
};
const getServices = async () => {
  loading.services = true;
  try {
    const { data } = await Api.get('/services');
    data.forEach((service) => {
      const key = service.name.toLowerCase();
      if (userServices[key]) userServices[key].id = service.id;
    });
  } catch (error) {
    console.log(error);
  }
  loading.services = false;
};
const getClientServices = async (clientId) => {
  const { data } = await Api.get(`/clients/${clientId}/services`);
  data.forEach((service) => {
    const key = service.service_name.toLowerCase();
    if (service.service_id === userServices[key].id) {
      userServices[key].clientServiceId = service.id;
      userServices[key].responsiblesId = JSON.parse(service.responsible_user_id ?? '[]').map(
        (id) => ({
          id,
        }),
      );
    }
  });
};
const onChangeBills = async (method, is_active_bills, userId) => {
  const isNewClient = method === 'post';
  const isSameValue = !!props.data.is_active_bills === is_active_bills;
  if ((!isSameValue && !isNewClient) || (isNewClient && is_active_bills))
    await Api.patch(
      `/clients/${userId ? userId : clientData.id}/bills/${is_active_bills ? 'enable' : 'disable'}`,
    );
};
const setServiceAction = async ({ fieldPath, id }, userId) => {
  try {
    const action = values[fieldPath] ? 'enable' : 'disable';
    await Api.patch(`/clients/${userId}/services/${id}/${action}`);
  } catch (error) {
    return false;
  }
};

const createService = async (service, userId) => {
  try {
    const { data } = await Api.post(`/clients/${userId}/services`, {
      email_to_notify: email.value,
      service_id: service.id,
    });
    userServices[service.key].clientServiceId = data.id;
  } catch (error) {
    console.log(error);
  }
};
const setResponsiblesService = async (service, userId) => {
  const responsibles = (
    service.id === userServices.auditoria.id
      ? auditingResponsible.value
      : contabilidadResponsible.value
  ).filter((responsible) => !service.responsiblesId.find((r) => r.id === responsible.id));
  if (responsibles.length)
    await Api.patch(`/clients/${userId}/services/${service.id}/responsibles`, {
      responsible_user_id: responsibles.map((responsible) => responsible.id),
    });
};
const deleteResponsiblesService = async (service, userId) => {
  const dataResponsible =
    service.id === userServices.auditoria.id
      ? auditingResponsible.value
      : contabilidadResponsible.value;
  const responsibles = service.responsiblesId.filter(
    (responsible) => !dataResponsible.find((r) => r.id === responsible.id),
  );
  if (responsibles.length)
    await Api.delete(`/clients/${userId}/services/${service.id}/responsibles`, {
      responsible_user_id: responsibles.map((responsible) => responsible.id),
    });
};
const onActiveServices = async (userId) => {
  let promise = [];
  Object.values(userServices).forEach(async (service) => {
    if (service.isActive != values[service.fieldPath]) {
      const serviceAction = service.clientServiceId
        ? setServiceAction(service, userId)
        : createService(service, userId);
      promise.push(serviceAction);
      await serviceAction;
    }
  });
  await getClientServices(userId);
  Object.values(userServices).forEach(async (service) => {
    if (values[service.fieldPath]) {
      const deleteResponsible = setResponsiblesService(service, userId);
      const addResponsible = deleteResponsiblesService(service, userId);
      promise.push(deleteResponsible);
      promise.push(addResponsible);

      await deleteResponsible;
      await addResponsible;
    }
  });
};
const onSubmit = handleSubmit(
  async ({ name, email, tin, tax_regime_id, entity_type, is_active_bills }) => {
    try {
      let userId = props.data.id;
      let method = 'post',
        url = `/clients`;

      if (userId) {
        method = 'put';
        url = `/clients/${userId}`;
      }
      const { data } = await Api[method](url, {
        name,
        email,
        tin,
        tax_regime_id,
        entity_type,
        phone_number: phoneNumberMaska.unmasked,
      });
      userId = data.id;
      await onChangeBills(method, is_active_bills, userId);
      await onActiveServices(userId);
      const isContabilidadActive = isActiveContabilidad.value;
      isModalActive.value = false;
      emit('success:save');
      Notify('success', clientData.value.id ? 'Cliente actualizado' : 'Cliente agregado');
      if (isContabilidadActive) router.push({ path: `/lista-clientes/corrida-mensual/${userId}` });
    } catch (error) {
      console.log(error);
    }
  },
);
// COMPUTED
const saveDisabled = computed(() => {
  return !meta.value.valid || (props.data.id && !meta.value.touched);
});
const modalController = computed(() =>
  props.data.id
    ? {
        saveLabel: 'Guardar cambios',
        header: 'Editar cliente',
      }
    : {
        saveLabel: 'Agregar cliente',
        header: 'Agregar cliente',
      },
);
const hasServicesActive = computed(() => {
  const { auditoria, contabilidad } = userServices;
  return auditoria.isActive || contabilidad.isActive;
});
const hasAuditingInfo = computed(() => {
  const { auditoria } = userServices;
  return auditoria.is_load_info;
});
const hasContabilidadInfo = computed(() => {
  const { contabilidad } = userServices;
  return contabilidad.is_load_info;
});
const isDisabledModal = computed(() => isSubmitting.value);

const checablesDropdownParams = computed(() => {
  const { ADMINISTRADOR, AUDITOR, OPERADOR } = userRoles;
  return {
    auditoria: [
      'service_name=Auditoria',
      `user_type_names=${JSON.stringify([ADMINISTRADOR, AUDITOR])}`,
    ],
    contabilidad: [
      'service_name=Contabilidad',
      `user_type_names=${JSON.stringify([ADMINISTRADOR, OPERADOR])}`,
    ],
  };
});

// WATCH
watch(isOpen, (value) => (isModalActive.value = value));
watch(
  clientData,
  async (value) => {
    try {
      const { legal } = entityTypesData;
      setEntityTypes({
        [value.entity_type === legal.name ? 'legal' : 'physical']: value.entity_type,
      });
      Object.values(value.services ?? {}).forEach(({ service_name, is_load_info }) => {
        const serviceName = service_name.toLowerCase();
        userServices[serviceName].is_load_info = !!is_load_info;
        userServices[serviceName].isActive = true;
      });
      const { auditoria, contabilidad } = userServices;
      setValues({
        email: value.email,
        name: value.name,
        tin: value.tin,
        tax_regime_name: value.tax_regime_name,
        entity_type: value.entity_type,
        tax_regime_id: value.tax_regime_id,
        phone_number: value.phone_number,
        is_active_bills: !!value.is_active_bills,
        is_active: !!value.is_active,
        isActiveAuditing: auditoria.isActive,
        isActiveContabilidad: contabilidad.isActive,
      });
      loading.clientServices = true;
      await getClientServices(value.id);
      setValues({
        auditingResponsible: [...auditoria.responsiblesId],
        contabilidadResponsible: [...contabilidad.responsiblesId],
      });
    } catch (error) {
      console.log(error);
    }
    loading.clientServices = false;
  },
  { inmediate: true },
);
watch(isModalActive, (value) => {
  emit('update:active', value);
  emit('update:is-open', value);
  if (!value) {
    resetForm();
    userServices.auditoria.is_load_info = false;
    userServices.contabilidad.is_load_info = false;
    userServices.auditoria.responsiblesId = [];
    userServices.contabilidad.responsiblesId = [];
    userServices.auditoria.isActive = false;
    userServices.contabilidad.isActive = false;
  }
});

watch(
  isActiveAuditing,
  async () => (schema.fields.auditingResponsible = auditingResponsibleRules.value),
);
watch(
  isActiveContabilidad,
  async () => (schema.fields.contabilidadResponsible = contabilidadResponsibleRules.value),
);
onMounted(async () => {
  const { auditoria, contabilidad } = userServices;
  if (!auditoria.id || !contabilidad.id) await getServices();
});
</script>

<style lang="sass" scoped>
#clientModal
  :deep(.modal-content)
    overflow: hidden
  .form-container
    max-height: 709px
  form
    display: grid
    column-gap: 20px
    row-gap: 24px
    grid-template-columns: repeat(2, 1fr)
    .w-100
      grid-column-start: 1
      grid-column-end: 3
    .warning-flag
      background-color: $color-blue-hues
      border-left: 2px solid $grey-dark-second
      padding: 4px 16px
      justify-self: end
      font-weight: 600
      grid-column-start: 1
      grid-column-end: 3
      font-size: $f-sm
      color: $black
      border-radius: 0px 5px 5px 0px
    .divider
      border-bottom: 1px $black solid
    .responsables-dropdown
      width: 100%
      > :deep(.dropdown)
        width: fit-content
      :deep(.dropdown-content)
        max-height: 210px
        overflow: auto
        width: 397px

  @media (max-width: $bp-md)
    #clientModal
      form
        grid-template-columns: 1fr
</style>
