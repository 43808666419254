<template>
  <ModalOverview
    :id="dataConcept.id"
    v-model:is-open="isModalActive"
    v-model:comments="comments"
    v-model:observations="observations"
    :is-client="isClient"
    :title="dataConcept.long_name"
    :auditing-id="auditingId"
  />
  <section :class="`${isClient ? 'is-client' : ''}  ${classHelper}`" class="card">
    <o-loading :active="updateSequence.target" :full-page="false" />
    <div class="card-content is-flex is-flex-direction-column">
      <div class="is-flex pb-3 is-align-items-center">
        <o-skeleton v-if="updateSequence.loading" class="mr-2" width="19px" />
        <span v-else class="mr-2 has-text-weight-semibold f-xs">#{{ dataConcept.sequence }}</span>
        <Tag
          class="short-name is-uppercase f-xs has-text-weight-semibold"
          :label="dataConcept.short_name"
        />
        <o-tooltip
          class="status-info"
          :class="`${isClient ? 'ml-auto' : 'ml-2'} `"
          :label="semaphoring.text"
          variant="info"
        >
          <o-icon :class="`is-${semaphoring.icon}`" :icon="semaphoring.icon" />
        </o-tooltip>
        <o-icon v-if="isDrag" class="ml-auto drag-arrow" icon="arrow-all" />
        <Button
          v-if="!isDrag && allowEditConcept"
          class="btn-edit ml-auto"
          icon-left="pencil"
          variant="primary"
          @click="() => $emit('edit-concept', dataConcept)"
        />
      </div>
      <Tag
        class="mb-3"
        :label="dataConcept.concept_type_name"
        rounded
        :custom-color="dataConcept.concept_type_color"
      />
      <h3 class="is-uppercase break-word has-text-weight-bold fh-xs">
        {{ dataConcept.long_name }}
      </h3>
      <p
        class="has-text-weight-semibold f-xxs is-uppercase"
        :class="`has-text-${indicatorStatus.variant} ${isClient ? 'pb-0' : 'pb-4'}`"
      >
        {{ indicatorStatus.label }}
      </p>
      <template v-if="!isDrag">
        <template v-if="!isClient">
          <h3 class="is-uppercase has-text-weight-bold f-sm">Descripción:</h3>
          <p class="pb-4 f-sm">
            {{ dataConcept.description || 'Sin descripción disponible' }}
          </p>
        </template>
        <template v-if="dataConcept.conclusion && !isClient">
          <h3 class="is-uppercase has-text-weight-bold f-sm">conclusión:</h3>
          <p class="pb-4 f-sm">
            {{ dataConcept.conclusion }}
          </p>
        </template>
        <Tag v-if="!isClient" class="f-xs mt-auto is-align-self-center" expanded variant="bluehues">
          <span class="has-text-weight-bold mr-1">Horas:</span>
          <span class="mr-3 is-uppercase"
            >Supervisor:<span class="pl-1">{{ hoursWorked.responsibleTime }}</span></span
          >
          <span class="is-uppercase"
            >Auditor:<span class="pl-1">{{ hoursWorked.assistantTime }}</span></span
          >
        </Tag>
      </template>
    </div>
    <footer v-if="!isDrag" class="card-footer">
      <o-collapse
        :key="id"
        :class="isClient ? 'is-client' : ''"
        animation="slide"
        :open="isOpen"
        @open="isOpen"
      >
        <template #trigger="props">
          <Button variant="primary" @click="seeOperations">
            <p class="btn-label">Ir a detalle de concepto</p>
          </Button>
          <Button variant="dark" @click="openModal">
            <p class="btn-label">{{ `Observaciones ${!isClient ? 'y comentarios' : ''}` }}</p>
          </Button>
          <o-icon v-if="!isClient" :icon="props.open ? 'chevron-up' : 'chevron-down'" />
        </template>
        <div class="auditoria-collapse">
          <template v-if="loading.collapse">
            <o-loading :active="loading.collapse" :full-page="false">
              <o-icon icon="loading" size="large" spin> </o-icon>
            </o-loading>
          </template>
          <template v-else-if="operationData">
            <h3 class="is-uppercase has-text-weight-bold f-sm">
              procedimiento
              <o-icon
                :icon="conceptReady.icon"
                size="small"
                :variant="conceptReady.variant"
              ></o-icon>
            </h3>
            <p class="pb-4 f-sm">
              {{ operationData.description }}
            </p>
          </template>
          <h3 v-else class="mb-4">Sin procedimientos en transcurso</h3>
          <Button
            v-if="data.status !== 'COMPLETADO'"
            :disabled="!isAllowEnd"
            label="Terminar concepto"
            variant="danger"
            expanded
            @click="$emit('open:end-concept', data)"
          />
          <!-- <p class="is-uppercase operation-footer f-xs has-text-weight-medium">
            <span class="mr-2"
              >preparado
              <o-icon
                :icon="dataConcept.is_prepared ? 'check-circle' : 'close-circle'"
                size="small"
                :variant="dataConcept.is_prepared ? 'primary' : 'danger'"
            /></span>
            <span
              >aprobado
              <o-icon
                :icon="dataConcept.is_approved ? 'check-circle' : 'close-circle'"
                size="small"
                :variant="dataConcept.is_approved ? 'primary' : 'danger'"
            /></span>
          </p> -->
        </div>
      </o-collapse>
    </footer>
  </section>
</template>
<script>
import {
  ref,
  onBeforeMount,
  getCurrentInstance,
  toRefs,
  watchEffect,
  computed,
  reactive,
} from 'vue';
import { Tag, Button } from '@/components';
import ModalOverview from './modals/ModalOverview.vue';
import { useRouter } from 'vue-router';
import timestamp from '@/utils/timestamp';
import { useAuthStore } from '@/store';
import { userRoles } from '@/config/constants';
import { PermissionValidator } from '@/utils/Secure';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    Tag,
    Button,
    ModalOverview,
  },
  inheritAttrs: false,
  props: {
    classHelper: { type: String, default: '' },
    data: { type: Object, default: () => ({}) },
    id: { type: [Number, String], default: null },
    auditingId: { type: [Number, String], default: null },
    isResponsible: { type: Boolean, default: false },
    isDrag: { type: Boolean, default: false },
    updateSequence: { type: Object, default: () => ({ loading: false, target: false }) },
  },
  emits: ['update:selected', 'update:data', 'open:end-concept', 'edit-concept'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const { getAUTH } = useAuthStore();
    const router = useRouter();
    const isModalActive = ref(false);
    const isOpen = ref(false);
    const { data } = toRefs(props);
    const dataConcept = ref(props.data);
    const loading = reactive({ collapse: false, updateSequence: false });
    const operationData = ref(false);
    const comments = ref(JSON.parse(dataConcept.value?.comments || '[]'));
    const observations = ref(JSON.parse(dataConcept.value.observations));
    const conceptStatus = {
      CREADO: 'CREADO',
      EN_TRANSCURSO: 'EN TRANSCURSO',
      NECESITA_ATENCION: 'NECESITA ATENCION',
      PENDIENTE: 'PENDIENTE',
      COMPLETADO: 'COMPLETADO',
    };
    const getDataCollapse = async () => {
      loading.collapse = true;
      try {
        const { data } = await Api.get(
          `/concept/${dataConcept.value.id}/operation?sort=sequence&per_page=1`,
        );
        operationData.value = data.length
          ? data.find((o) => o.status === 'CREADA' || o.status === 'EN PROGRESO')
          : false;
      } catch (error) {
        console.log(error);
      }
      loading.collapse = false;
    };
    const allowEditConcept = computed(() => {
      const { Update } = Permissions.AuditingConcepts;
      return PermissionValidator(Update);
    });
    const isClient = computed(() => getAUTH.role === userRoles.CLIENTE);
    const isAllowEnd = computed(() => {
      const isEndOperations = !!dataConcept.value.operations_finished;
      return isEndOperations && props.isResponsible;
    });
    onBeforeMount(() => {
      if (!props.isDrag) getDataCollapse();
    });
    watchEffect(() => {
      emit('update:data', dataConcept.value);
      dataConcept.value = data.value;
    });
    return {
      allowEditConcept,
      isOpen,
      isClient,
      isModalActive,
      dataConcept,
      comments,
      isAllowEnd,
      loading,
      operationData,
      observations,
      openModal: () => (isModalActive.value = true),
      seeOperations: () => {
        router.push({
          name: 'AuditingOperations',
          params: {
            auditingId: props.auditingId,
            conceptoId: props.id,
          },
        });
      },
      conceptReady: computed(() => {
        const { is_prepared, is_approved } = dataConcept.value;
        const is_ready = is_prepared && is_approved;
        return {
          icon: is_ready ? 'check-circle' : 'close-circle',
          variant: is_ready ? 'primary' : 'danger',
        };
      }),
      indicatorStatus: computed(() => {
        const _indicatorStatus = { label: 'CREADO', variant: 'grey' };
        const { status, assistant_time, responsible_time } = dataConcept.value;
        switch (status) {
          case conceptStatus.EN_TRANSCURSO:
            _indicatorStatus.variant = 'info';
            _indicatorStatus.label = 'EN TRANSCURSO';
            break;
          case conceptStatus.COMPLETADO:
            _indicatorStatus.label = 'COMPLETADO';
            _indicatorStatus.variant = 'primary';
            break;
          default:
            if (assistant_time || responsible_time) {
              _indicatorStatus.variant = 'info';
              _indicatorStatus.label = 'EN TRANSCURSO';
            } else {
              _indicatorStatus.variant = 'grey';
              _indicatorStatus.label = 'CREADO';
            }
            break;
        }
        return _indicatorStatus;
      }),
      semaphoring: computed(() => {
        const _semaphoring = { variant: 'grey', icon: 'timelapse' };
        switch (dataConcept.value.status) {
          case conceptStatus.EN_TRANSCURSO:
            _semaphoring.variant = 'info';
            _semaphoring.icon = 'timer-sand-complete';
            _semaphoring.text = 'EN TRANSCURSO';
            break;
          case conceptStatus.NECESITA_ATENCION:
            _semaphoring.variant = 'grey';
            _semaphoring.icon = 'alert';
            _semaphoring.text = 'NECESITA ATENCION';
            break;
          case conceptStatus.COMPLETADO:
            _semaphoring.icon = 'check-circle';
            _semaphoring.text = 'COMPLETADO';
            _semaphoring.variant = 'primary';
            break;
          default:
            _semaphoring.variant = 'grey';
            _semaphoring.text = 'CREADO';
            _semaphoring.icon = 'timelapse';
            break;
        }
        return _semaphoring;
      }),
      hoursWorked: computed(() => {
        let { assistant_time, responsible_time } = dataConcept.value;
        return {
          assistantTime: assistant_time ? timestamp.format('h:m', assistant_time) : 0,
          responsibleTime: responsible_time ? timestamp.format('h:m', responsible_time) : 0,
        };
      }),
    };
  },
};
</script>
<style lang="sass" scoped>
.card
    position: relative
    color: $black
    box-shadow: 0px 16px 8px rgba(0, 0, 0, 0.24)
    .drag-arrow  :deep(.mdi)::before
      color: $grey-300
    :deep(.button).btn-edit
      border-radius: 3px
      height: 24px
      width: 24px
      padding: 0
      .icon
          margin: 0 !important
          .mdi::before
            font-size: 18px
    .break-word
      word-wrap: break-word
    .card-content
        position: relative
        height: calc( 100% - 85px )
        :deep(.b-skeleton)
          width: 20px
        .short-name
          min-height: 26px
        $semaphoring: ( timer-sand-complete: ('color': $color-blue-plus) , check-circle: ('color': $color-green-misc), alert: ('color': $color-bright-sun), alert-circle: ('color': $color-radical-red), timelapse: ('color': $grey-dark))
        @each $className, $colorObject in $semaphoring
          .is-#{$className}
            color: map-get($colorObject, "color")
    .card-footer
      border-top: unset
      .auditoria-collapse
        padding: 24px 8px
      .o-clps
        width: 100%
        padding: 16px 24px
        background: $color-alice-blue
        :deep(.o-clps__trigger)
          display: grid
          grid-gap: 16px
          grid-template-columns: repeat(2,  minmax(112px, 112px)) 15px
          align-items: center
          place-content: center
          .button
            height: auto
            padding: 8px 8.5px
          .btn-label
            max-width: 86px
            white-space: pre-wrap
            line-height: 18px
            font-size: 11px
            font-weight: 600
        :deep(.o-clps__content)
          width: 100%
          right: 0
          position: absolute
          padding: 0px 32px
          z-index: 1
          margin-top: 16px
          border-top: 1.5px solid #C7DAE6
          background: $color-alice-blue
          box-shadow: 0px 16px 8px rgba(0, 0, 0, 0.24)
          .operation-footer
            color: $grey-dark-second
.card.is-client
  .card-content
    padding-bottom: 16px
  .card-footer
    .auditoria-collapse
      padding: 24px 8px
    .o-clps
      width: 100%
      padding: 16px 24px
      background: $color-alice-blue
      :deep(.o-clps__trigger)
        grid-template-columns: 125px 112px
        .button
          height: 100%
</style>
