<template>
  <div class="document-handler W-100 is-flex is-justify-content-center is-align-items-center">
    <div v-if="!data.title" class="is-flex is-flex-direction-column">
      <template v-for="document in documents" :key="`document-${document.key}`">
        <div
          v-if="
            (document.show && isCLient) || (document.show && document.fileName.length && !isCLient)
          "
          class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"
        >
          <Tag
            class="is-capitalized mr-2"
            :variant="document.variant"
            :label="document.key.toLowerCase()"
          />
          <o-skeleton v-if="loading[document.key]" width="90%" position="left" />
          <DownloadDocument
            v-else
            class="mr-auto"
            :disabled="documentsDownload(document)"
            :api-url="apiDownloadDocument"
            :file-name="document.fileName.length ? document.fileName : 'Sin documento'"
          />
          <Button
            v-if="!isOperationClose && allowDeleteDocuments && document.fileName.length"
            :loading="loading[document.key]"
            class="tiny-button ml-3"
            variant="danger"
            icon-right="close"
            :disabled="disabled"
            @click="handleDelete(document.key)"
          />
        </div>
        <o-tooltip
          v-else-if="allowUploadDocuments"
          :active="
            document.key === documents.FINAL.key &&
            !documents.PRELIMINAR.fileName.length &&
            !isOperationClose
          "
          label="Archivo preliminar requerido"
          position="bottom"
          variant="info"
        >
          <FilePicker
            :title="`Archivo ${document.key.toLowerCase()}`"
            :disabled="
              disabled ||
              (document.key === documents.FINAL.key && !documents.PRELIMINAR.fileName.length) ||
              isOperationClose
            "
            :accept="documentsAccepted"
            :set-file-name-by-patch="`/concept/${conceptoId}/operation/${data.id}/filename`"
            :api-url="`/concept/${conceptoId}/operation/${data.id}/presigned/upload`"
            :document-type="document.key"
            @success="onSuccess($event, document.key)"
          />
        </o-tooltip>
      </template>
      <p v-if="!isCLient" class="docs-accept W-100 has-text-centered f-xxs">
        Archivos permitidos: {{ documentsAccepted }}
      </p>
    </div>
  </div>
</template>
<script>
import { DownloadDocument, FilePicker, Button, Tag } from '@/components';
import useDialog from '@/utils/composables/useDialog';
import { useProgrammatic } from '@oruga-ui/oruga-next';
import { ref, getCurrentInstance, computed } from 'vue';
import ModalConfirm from '@/components/modals/ModalConfirm.vue';
import { Permissions } from '@/utils/Secure';
import { PermissionValidator } from '@/utils/Secure';
export default {
  components: {
    Button,
    DownloadDocument,
    FilePicker,
    Tag,
  },
  props: {
    conceptoId: { type: [String, Number], required: true },
    data: { type: Object, default: () => {} },
    disabled: { type: Boolean, default: false },
    disabledDownload: { type: Boolean, default: false },
    isCLient: { type: Boolean, default: false },
  },
  emits: ['update:data'],
  setup(props, { emit }) {
    const status = {
      FINALIZADA: 'FINALIZADA',
    };
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;

    const { Notify } = useDialog();
    const { oruga } = useProgrammatic();

    const loading = ref({ PRELIMINAR: false, FINAL: false });
    const apiDownloadDocument = ref(
      `/concept/${props.conceptoId}/operation/${props.data.id}/presigned/download`,
    );
    const confirmDeleteFile = async (key) => {
      const instance = oruga.modal.open({
        component: ModalConfirm,
        canCancel: false,
        props: {
          title: `Eliminación de documento`,
          message: `¿Seguro que quieres eliminar este documento? <br /> <b>${documents.value[key].fileName}</b>`,
          confirmLabel: 'Eliminar',
          rejectLabel: 'Cancelar',
          confirmVariant: 'danger',
          rejectVariant: '',
        },
        trapFocus: true,
      });
      return instance.promise;
    };
    const onDeleteDocument = async (key) => {
      try {
        const _fileName = JSON.parse(props.data.file_name);
        const name = _fileName[key];
        await Api.delete(
          `/concept/${props.conceptoId}/operation/${props.data.id}/file/${key.toLowerCase()}`,
        );
        delete _fileName[key];
        emit('update:data', {
          id: props.data.id,
          file_name: JSON.stringify(_fileName),
        });
        Notify('success', 'Documento eliminado', `El documento ${name} fue eliminado.`);
      } catch (error) {
        console.log(error);
      }
    };
    const handleDelete = async (key) => {
      loading.value[key] = true;
      const { action } = await confirmDeleteFile(key);
      if (action === 'yes') await onDeleteDocument(key);
      loading.value[key] = false;
    };
    const onSuccess = (file_name, key) => {
      const _fileName = JSON.parse(props.data.file_name);
      _fileName[key] = file_name;
      emit('update:data', {
        id: props.data.id,
        file_name: JSON.stringify(_fileName),
      });
    };
    const isOperationClose = computed(() => props.data.status === status.FINALIZADA);
    const documents = computed(() => {
      const { data, isCLient } = props;
      let _documents = JSON.parse(data.file_name);
      let documentObject = {
        PRELIMINAR: {
          key: 'PRELIMINAR',
          variant: 'link',
          fileName: _documents.PRELIMINAR ?? '',
          show: true,
        },
        FINAL: {
          key: 'FINAL',
          variant: 'success',
          fileName: _documents.FINAL ?? '',
          show: true,
        },
      };
      if (isCLient && isOperationClose.value) {
        if (_documents.FINAL || _documents.PRELIMINAR)
          documentObject.FINAL.fileName = _documents.FINAL ?? _documents.PRELIMINAR;
        else documentObject.FINAL.fileName = '';
        documentObject.PRELIMINAR.show = false;
      }
      return documentObject;
    });
    const documentsAccepted = computed(() => {
      const _accepted = JSON.parse(props.data.document_types);
      return _accepted.length ? _accepted.join(', ') : '.pdf';
    });
    const allowDeleteDocuments = computed(() => {
      const { DeleteFileFinal, DeleteFilePreliminar } = Permissions.ConceptoOperations;
      return PermissionValidator(DeleteFileFinal) || PermissionValidator(DeleteFilePreliminar);
    });
    const allowUploadDocuments = computed(() => {
      const { Upload } = Permissions.ConceptoOperations;
      return PermissionValidator(Upload);
    });
    const documentsDownload = (document) => {
      return !document.fileName.length || props.disabledDownload;
    };
    return {
      // Data
      apiDownloadDocument,
      status,
      loading,

      // Computed
      allowUploadDocuments,
      allowDeleteDocuments,
      documents,
      documentsAccepted,
      isOperationClose,
      documentsDownload,

      // method
      handleDelete,
      onSuccess,
    };
  },
};
</script>
<style lang="sass" scoped>
.document-handler
  width: 100%
  .docs-accept
    color:$grey-info
  > div
    width: 100%
    gap: 10px
    max-width: 380px
  :deep(.tiny-button)
    min-width: 16px
    min-height: 16px
    width: 16px
    height: 16px
    padding: 0
    .icon
      margin: 0 !important
      .mdi::before
        font-size: 14px
      .mdi-loading::before
        font-size: 10px !important
.W-100
  width: 100%
</style>
