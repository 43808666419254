<template>
  <ModalAddHours
    v-if="isModalActive"
    v-model:is-open="isModalActive"
    :is-responsible="isResponsible"
    :operation="operation"
    :reload="reload"
    @success:add="updateTime"
  />
  <ModalSolicitarTermino
    v-if="isModalTerminoActive"
    v-model:isOpen="isModalTerminoActive"
    :operation="operation"
    @succes:request-end="requestEnd"
  />
  <div v-show="!readyToShow || delayerViewChange" class="empty-content w-100">
    <o-loading :active="true" :full-page="false">
      <o-icon icon="loading" size="large" spin> </o-icon>
    </o-loading>
  </div>
  <template v-if="conceptoId && !delayerViewChange">
    <DragOperationTable v-if="isEnableSort" :columns="operationsData" :concepto-id="conceptoId" />
    <Table
      v-else
      v-show="readyToShow"
      id="table-procedimientos"
      ref="tableProcedimiento"
      v-model:data="operationsData"
      v-model:loading="isLoading"
      :api-url="apiUrl"
      :focusable="false"
      :class="isClient ? 'is-client' : ''"
      :per-page="999"
      empty-message="No se encontraron procedimientos"
      :row-class="(row) => (row.title ? 'is-title' : 'is-operation')"
      :show-detail-icon="false"
      detail-key="id"
      detailed
    >
      <o-table-column v-slot="props" width="90" label="ESTADO" field="status">
        <div class="is-flex is-justify-content-center is-align-items-center">
          <p
            v-if="!props.row.title"
            class="has-text-centered"
            :class="props.row.conclusion_request && !isClient ? 'conclusion-request' : ''"
          >
            <o-tooltip class="status-info" :label="props.row.status" variant="info">
              <o-icon
                class="status-icon"
                :icon="statusIcon(props.row.status)"
                :variant="props.row.status == 'FINALIZADA' ? 'primary' : 'light-grey'"
              />
            </o-tooltip>
          </p>
        </div>
      </o-table-column>
      <o-table-column
        v-slot="props"
        width="550"
        label="PROCEDIMIENTO"
        field="description"
        :td-attrs="(row) => ({ class: row.title ? 'is-title' : '' })"
      >
        <div class="is-flex is-align-items-center">
          <p :class="props.row.title ? 'is-title-operation' : ''">
            {{ props.row.description === 'is-end-title' ? '' : props.row.description }}
          </p>
        </div>
      </o-table-column>
      <o-table-column v-slot="props" label="ACTUALIZADO" field="last_updated" width="192">
        <div class="is-flex is-justify-content-center is-align-items-center">
          <p v-if="!props.row.title" class="has-text-centered">
            {{ formatLastUpdate(props.row.last_updated) }}
          </p>
        </div>
      </o-table-column>
      <o-table-column
        v-if="!isClient"
        v-slot="{ row }"
        width="134"
        label="SUPERVISOR"
        field="responsible_time"
      >
        <div class="is-flex is-justify-content-center is-align-items-center">
          <template v-if="!row.title">
            <p v-if="row.planned_time_supervisor" class="has-text-centered">
              <span
                :class="
                  isOutOfRange(row.responsible_time, row.planned_time_supervisor)
                    ? 'has-text-danger-dark has-text-weight-semibold'
                    : ''
                "
                >{{ accumulatedTime(row.responsible_time) }}</span
              >
              /
              <span>{{ accumulatedTime(row.planned_time_supervisor) }}</span>
              horas
            </p>
            <p v-else class="has-text-centered">
              {{ accumulatedTime(row.responsible_time) }} horas
            </p>
          </template>
        </div>
      </o-table-column>
      <o-table-column
        v-if="!isClient"
        v-slot="{ row }"
        width="134"
        label="AUDITOR"
        field="assistant_time"
      >
        <div class="is-flex is-justify-content-center is-align-items-center px-1">
          <template v-if="!row.title">
            <p v-if="row.planned_time_auditor" class="has-text-centered">
              <span
                :class="
                  isOutOfRange(row.assistant_time, row.planned_time_auditor)
                    ? 'has-text-danger-dark has-text-weight-semibold'
                    : ''
                "
                >{{ accumulatedTime(row.assistant_time) }}</span
              >
              /
              <span>{{ accumulatedTime(row.planned_time_auditor) }}</span>
              horas
            </p>
            <p v-else class="has-text-centered">{{ accumulatedTime(row.assistant_time) }} horas</p>
          </template>
        </div>
      </o-table-column>
      <o-table-column
        v-if="!isClient"
        v-slot="{ row }"
        width="110"
        label="EFECTIVAS"
        field="accumulated_time"
      >
        <div class="is-flex is-justify-content-center is-align-items-center">
          <p
            v-if="!row.title"
            class="has-text-centered"
            :class="
              isOutOfRange(row.responsible_time, row.planned_time_supervisor) ||
              isOutOfRange(row.assistant_time, row.planned_time_auditor)
                ? 'has-text-danger-dark has-text-weight-semibold'
                : ''
            "
          >
            {{ accumulatedTime(row.accumulated_time) }} horas
          </p>
        </div>
      </o-table-column>
      <o-table-column
        v-if="allowUpload"
        v-slot="{ row }"
        label="DOCUMENTO"
        width="400"
        field="descarga"
      >
        <DocumentHandle :data="row" :concepto-id="conceptoId" @update:data="updateOperationData" />
      </o-table-column>
      <o-table-column
        v-if="allowAddHour"
        v-slot="{ row }"
        width="24"
        :td-attrs="() => ({ class: 'add-hours-tr' })"
      >
        <div class="px-3 is-flex is-justify-content-center is-align-items-center">
          <Button
            v-if="!row.title && allowUpdate && row.status !== 'FINALIZADA'"
            class="mr-2 btn-edit"
            icon-right="pencil"
            variant="primary"
            @click="$emit('edit:operation', row)"
          />
          <Button
            v-if="!row.title && row.status !== 'FINALIZADA'"
            :disabled="Boolean(row.conclusion_request)"
            icon-right="plus"
            variant="primary"
            @click="addHours(row)"
          />

          <div
            v-else-if="!row.title && row.status == 'FINALIZADA' && row.indicator"
            class="indicator"
          >
            <o-tooltip
              class="semaphoring-indicator"
              :label="setIndicator(row.indicator).label"
              variant="info"
              multiline
            >
              <o-icon
                :icon="setIndicator(row.indicator).icon"
                :variant="setIndicator(row.indicator).variant"
              />
            </o-tooltip>
          </div>
        </div>
      </o-table-column>
      <o-table-column
        v-if="isClient"
        v-slot="{ row }"
        width="400"
        label="DOCUMENTO"
        field="descarga"
      >
        <div class="is-flex is-justify-content-center is-align-items-center">
          <template v-if="!row.title">
            <DocumentHandle :data="row" :concepto-id="conceptoId" is-c-lient />
          </template>
        </div>
      </o-table-column>
      <o-table-column v-slot="{ row }" width="24" field="details">
        <div>
          <div v-if="!row.title" class="link details-action" @click="toggleDetails(row)">
            <o-icon pack="mdi" :icon="row.isOpen ? 'chevron-up' : 'chevron-down'" size="14px" />
          </div>
          <template v-else>
            <p
              v-if="allowUpdate && row.description !== 'is-end-title'"
              class="link primary-link has-text-primary has-text-weight-semibold f-sm"
              role="button"
              @click="$emit('edit:operation', row)"
            >
              <span>Editar</span>
              <o-icon icon="pencil" variant="primary" />
            </p>
            <p
              v-if="allowDeleteOperation"
              class="link delete-link has-text-danger has-text-weight-semibold f-sm"
              role="button"
              @click="deleteOperation(row)"
            >
              <span v-if="row.description !== 'is-end-title'">Eliminar</span>
              <o-icon
                icon="close"
                variant="danger"
                :size="row.description !== 'is-end-title' ? undefined : 'small'"
              />
            </p>
          </template>
        </div>
      </o-table-column>
      <template #detail="props">
        <OperationsClientDetails v-if="isClient" :data="props" />
        <OperationsDetails
          v-else
          :data="props"
          :is-responsible="isResponsible"
          @open-modal-request="openModalRequest"
          @update:operation-data="updateOperationData"
          @reload:auditing="$emit('reload:auditing')"
        />
      </template>
    </Table>
  </template>
</template>
<script>
import useTableOperation from './FragmentsOperations/useTableOperation';
import ModalConfirm from '@/components/modals/ModalConfirm.vue';
import { useProgrammatic } from '@oruga-ui/oruga-next';
import { Table, Button, ModalAddHours, ModalSolicitarTermino } from '@/components';
import { OperationsDetails, OperationsClientDetails, DocumentHandle } from './FragmentsOperations';
import { computed, ref, toRefs, watch, getCurrentInstance } from 'vue';
import { useComponentUtils } from '@/components/conf/composables';
import DragOperationTable from '@/components/Tables/FragmentsOperations/DragOperationTable.vue';
export default {
  components: {
    Table,
    Button,
    DragOperationTable,
    DocumentHandle,
    ModalAddHours,
    ModalSolicitarTermino,
    OperationsDetails,
    OperationsClientDetails,
  },
  inheritAttrs: false,
  props: {
    conceptoId: { type: [String, Number], default: null },
    isResponsible: { type: Boolean, default: false },
    isClient: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
  emits: ['update-time', 'update:loading', 'edit:operation', 'update:data', 'reload:auditing'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const {
      accumulatedTime,
      allowAddHour,
      allowDeleteOperation,
      allowUpdate,
      allowUpload,
      formatLastUpdate,
      setIndicator,
      statusIcon,
      isOutOfRange,
      isEnableSort,
    } = useTableOperation();
    const Api = proxy?.Api;
    const { conceptoId, loading } = toRefs(props);
    const { oruga } = useProgrammatic();
    const isLoading = ref(props.loading);
    const operationsData = ref([]);
    const { table } = useComponentUtils();
    const tableProcedimiento = ref(null);
    const operation = ref(null);
    const isModalActive = ref(false);
    const isModalTerminoActive = ref(false);
    const isFirsTime = ref(true);
    const reload = async (clean = false) => {
      if (tableProcedimiento.value) await tableProcedimiento.value.reload(clean);
    };
    const getIdxElement = (operationId) =>
      operationsData.value.findIndex((o) => o.id == operationId);
    const elementIdToDelete = ref(0);
    const requestEnd = ({ operationId, conclusionsUpdated }) => {
      const idx = getIdxElement(operationId);
      operationsData.value[idx].conclusions = conclusionsUpdated;
      operationsData.value[idx].conclusion_request = 1;
    };
    const openModalRequest = (_operation) => {
      operation.value = _operation;
      isModalTerminoActive.value = true;
    };
    const updateOperationData = ({ id, ..._operationData }) => {
      const idx = getIdxElement(id);
      for (const key in _operationData) {
        operationsData.value[idx][key] = _operationData[key];
      }
    };
    const handleDelete = async () => {
      await Api.delete(`/concept/${props.conceptoId}/operation/${elementIdToDelete.value}`);
      const idx = getIdxElement(elementIdToDelete.value);
      operationsData.value.splice(idx, 1);
    };
    const confirmDeleteOperation = async (description) => {
      const instance = oruga.modal.open({
        component: ModalConfirm,
        canCancel: false,
        props: {
          action: handleDelete,
          title: `¿Estás seguro de eliminar el título?`,
          message: `El título <b>"${description}"</b> será <b>eliminado</b>.`,
          needReloadTable: true,
          cleanReload: true,
        },
        trapFocus: true,
      });
      return instance.promise;
    };
    const deleteOperation = async ({ id, description, title }) => {
      if (!title) return;
      isLoading.value = true;
      try {
        elementIdToDelete.value = id;
        if (description === 'is-end-title') {
          await handleDelete();
        } else {
          const { action } = await confirmDeleteOperation(description);
          if (action !== 'yes') throw '';
        }
      } catch (error) {
        console.log(error);
      }
      isLoading.value = false;
    };

    const delayerViewChange = ref(false);
    const readyToShow = computed(() => {
      return isFirsTime.value || !operationsData.value.length
        ? !(isLoading.value || operationsData.value.length)
        : true;
    });

    watch(isEnableSort, () => {
      delayerViewChange.value = true;
      setTimeout(() => {
        delayerViewChange.value = false;
      }, 10);
    });
    watch(operationsData, (value) => emit('update:data', value));
    watch(readyToShow, () => (isFirsTime.value = false));
    watch(conceptoId, () => {
      isFirsTime.value = true;
      reload(true);
    });
    watch(tableProcedimiento, () => {
      if (tableProcedimiento.value) table.value = tableProcedimiento.value;
    });
    watch(loading, (v) => (isLoading.value = v));
    watch(isLoading, (v) => emit('update:loading', v));
    return {
      isEnableSort,
      delayerViewChange,
      accumulatedTime,
      allowUpdate,
      isFirsTime,
      isLoading,
      readyToShow,
      allowDeleteOperation,
      allowUpload,
      allowAddHour,
      openModalRequest,
      tableProcedimiento,
      isModalActive,
      operation,
      requestEnd,
      deleteOperation,
      table,
      isModalTerminoActive,
      operationsData,
      updateOperationData,
      setIndicator,
      reload,
      isOutOfRange,
      addHours: (_operation) => {
        operation.value = _operation;
        isModalActive.value = true;
      },
      apiUrl: computed(() => `concept/${conceptoId.value}/operation`),
      formatLastUpdate,
      statusIcon,
      updateTime: (data) => emit('update-time', data),
      toggleDetails: (row) => {
        if (tableProcedimiento.value) {
          row.isOpen = !row.isOpen;
          tableProcedimiento.value.oTable.toggleDetails(row);
        }
      },
    };
  },
};
</script>
<style lang="sass" scoped>
#table-procedimientos
  :deep(.button).btn-edit
    border-radius: 3px
    height: 24px
    width: 24px
    padding: 0
    .icon
        margin: 0 !important
        .mdi::before
          font-size: 18px
  .has-text-danger-dark
    color: $color-radical-red !important
  .conclusion-request
    position: relative
    &::before
      display: block
      border-radius: 10px
      position: absolute
      content: ' '
      top: 0
      right: -5px
      width: 8px
      height: 8px
      background: $danger
  .semaphoring-indicator > :first-child
    max-width: 123px
    font-size: $f-xs
    padding: 6px
  .status-info > :first-child
    padding: 6px
    font-size: $f-xxs
  .details-action, .details-action .icon
    height: 100%
  :deep(.table)
    .status-icon .mdi-timelapse::before
      color: $grey-info
    tbody tr
      > .add-hours-tr
        div button, div .indicator
          width: 24px
          height: 24px
          max-width: 24px
          max-height: 24px
          padding: 0
          .icon, .mdi
            margin: 0
            height: 24px
            .mdi::before
              line-height: 24px
        div .indicator
          .icon .mdi-check-circle::before
              color: #3bb8a8a1
      &.detail
        background-color: $color-alice-blue
        td
          padding: 20px
  @include from($bp-md)
    :deep(.table)
      height: max-content
      border-radius: 8px
      width: calc( 100% - 2px )
      thead
        th
          padding: 18px 10px
        :first-child
          border-radius: 8px 0 0 0
        :last-child
          border-radius:  0 8px 0 0
        :nth-child(2) > span
          justify-content: flex-start
      tbody
        tr
          background-color: $grey-200
          > :first-child > div
            border-radius: 8px 0 0 8px
          > :last-child > div
            border-radius: 0 8px 8px 0
          td
            padding: 8px 0
            height: 100%
            > :first-child
              height: 100%
          &.is-operation
            td
              > :first-child
                padding: 24px 10px
                background: $white
            > :nth-child(2) p
                line-height: 21px
                > div, .icon, .mdi
                  height: 21px
                .mdi::before
                  line-height: 13px
          &.is-title
            > :last-child
              position: relative
              .details-action
                display: none
              .link
                height: 24px
                white-space: nowrap
                right: 32px
                top: calc( 50% - 12px )
                display: none
                &.primary-link
                  right: 135px
                &:active
                  &.delete-link
                    color: red !important
                    .icon .mdi::before
                      color: red !important
            &:hover >
              td > :first-child
                background-color: $color-dodger-blue
              :last-child .link
                display: flex
                align-items: center
                position: absolute
            td
              > :first-child
                padding: 10px 0
                background: $color-blue-hues
                border-radius: 0
              > :first-child
                border-radius: 0
              .is-title-operation
                color: $black
                font-weight: 700
                text-transform: uppercase
  @include until($bp-md)
    :deep(.table)
      tbody tr.is-title
        td:not(.is-title)
          display: none
        td.is-title
          background: $color-blue-hues
          &::before
            content: unset
          .is-title-operation
            color: $black
            text-align: center
            font-weight: 700
            text-transform: uppercase
#table-procedimientos.is-client
  @include from($bp-md)
    :deep(.table)
      thead th
        &:nth-child(2) > span
          justify-content: flex-start
        &:nth-child(3) > span
          justify-content: center
      tbody tr
        &.is-title
          > :last-child
            .details-action
              display: none
            .delete-link
              display: none
          &:hover >
            td > :first-child
              background-color: $color-blue-hues
            :last-child .delete-link
              display: none
.empty-content
  position: relative
  height: 40vh
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center
</style>
<style lang="sass">
.has-text-light-grey
  color: #D6D8DA
</style>
