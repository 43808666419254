export const vapidKey =
  'BMPL_LLSJwmpEAdqRw--l_GTzbxAP8x5EPusB6gyN8LKkaTbULv0K9YZEXC77kZgAwkNHrUSSrWaJZhFB0CzSsg';
export const config = {
  apiKey: 'AIzaSyA_Hsrw4gtdkLPF5I6Bx1lzcVJrL1b4-Ak',
  authDomain: 'despacho-testing.firebaseapp.com',
  projectId: 'despacho-testing',
  storageBucket: 'despacho-testing.appspot.com',
  messagingSenderId: '271541649214',
  appId: '1:271541649214:web:67a2ab4150c09dc08cf66f',
  measurementId: 'G-HYQZNEM2WP',
};
